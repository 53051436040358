import React, { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, get, set, push } from 'firebase/database';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const firebaseConfig = {
    apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
    authDomain: "easy-tuition-dac4a.firebaseapp.com",
    projectId: "easy-tuition-dac4a",
    storageBucket: "easy-tuition-dac4a.appspot.com",
    messagingSenderId: "873415270262",
    appId: "1:873415270262:web:bda429aa2747af38346457",
    measurementId: "G-T286R1DSHZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const StudentEnrollPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const teacherUserID = urlParams.get('teacherUserID');
        const subject = urlParams.get('subject') || 'Not specified';
        const board = urlParams.get('board') || 'Not specified';
        const timeSlot = urlParams.get('timeSlot') || 'Not specified';
        const teacherName = urlParams.get('teacherName') || 'Teacher Name Not Available';
        const userId = urlParams.get('userId') || 'Unknown User';

        if (teacherUserID) {
            const teacherRef = ref(database, 'teachers/' + teacherUserID);
            get(teacherRef)
                .then(snapshot => {
                    if (snapshot.exists()) {
                        const teacher = snapshot.val();
                        displayTeacherInfo(teacher, subject, board, timeSlot, teacherName);
                    } else {
                        console.error('Teacher not found');
                        displayError('Teacher not found');
                    }
                })
                .catch(error => {
                    console.error("Error fetching teacher details:", error);
                    displayError('Error fetching teacher details');
                });
        } else {
            console.error('No teacherUserID in query parameters');
            displayError('Invalid request');
        }

        // Modal Close Event Listeners
        document.getElementById('modal-close').addEventListener('click', () => {
            hidePaymentModal();
        });

        document.getElementById('modal-close-thank-you').addEventListener('click', () => {
            hideThankYouModal();
        });

        // Modal Button Handlers
        document.getElementById('modal-yes').addEventListener('click', () => {
            savePaymentDetails();
            hidePaymentModal();
            showThankYouModal();
        });

        document.getElementById('modal-no').addEventListener('click', () => {
            hidePaymentModal();
        });

    }, []);

    const displayTeacherInfo = (teacher, subject, board, timeSlot, teacherName) => {
        document.getElementById('teacher-name').textContent = teacherName;
        document.getElementById('teacher-photo').src = teacher.personalDetails?.passportPhotoURL || '#';
        document.getElementById('subject').textContent = subject;
        document.getElementById('board').textContent = board;
        document.getElementById('time-slot').textContent = timeSlot;
        document.getElementById('date').textContent = new Date().toLocaleDateString();
    };

    const displayError = (message) => {
        const errorElem = document.createElement('div');
        errorElem.textContent = message;
        errorElem.className = 'text-red-500 font-bold mt-4';
        document.body.appendChild(errorElem);
    };

    const proceedToPayment = () => {
        window.open('https://payu.in/invoice/F0779739003D519FAEBB6EB274FC27937E7188F585220534625FAFB9C5BA7A91/00BD0775107D78FAD1A8FEBEA8417353',
      '_blank', '_blank');
        setTimeout(showPaymentModal, 2000);
    };

    const showPaymentModal = () => {
        document.getElementById('payment-modal').classList.remove('hidden');
    };

    const hidePaymentModal = () => {
        document.getElementById('payment-modal').classList.add('hidden');
    };

    const showThankYouModal = () => {
        document.getElementById('modal-thank-you').classList.remove('hidden');
    };

    const hideThankYouModal = () => {
        document.getElementById('modal-thank-you').classList.add('hidden');
        navigate(`/MyTeacher`);
    };

    const savePaymentDetails = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const teacherUserID = urlParams.get('teacherUserID');
        const subject = urlParams.get('subject') || 'Not specified';
        const board = urlParams.get('board') || 'Not specified';
        const timeSlot = urlParams.get('timeSlot') || 'Not specified';
        const teacherName = urlParams.get('teacherName') || 'Unknown Teacher';
        const userId = urlParams.get('userId') || 'Unknown User';

        if (teacherUserID && subject && userId) {
            const paymentRef = ref(database, 'pending-payments');
            const newPaymentRef = push(paymentRef);

            const paymentData = {
                teacherName,
                studentUserID: userId,
                subject,
                board,
                timeSlot,
                teacherUserID,
                paymentDate: new Date().toLocaleDateString(),
                paymentTime: new Date().toLocaleTimeString(),
                paymentApproved: false
            };

            try {
                await set(newPaymentRef, paymentData);
                console.log('Payment details saved successfully.');
            } catch (error) {
                console.error('Error saving payment details:', error);
            }
        } else {
            console.error('Required data is missing for payment.');
        }
    };

    return (
        <div className="container mx-auto p-4">
            <div id="teacher-info" className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-4">Teacher Details</h2>
                <div className="flex items-start mb-4">
                    <img id="teacher-photo" src="#" alt="Teacher Photo" className="w-24 h-24 object-cover rounded-full mr-4" />
                    <div>
                        <h3 id="teacher-name" className="text-lg font-semibold">Teacher Name</h3>
                    </div>
                </div>
                <p><strong>Subject:</strong> <span id="subject">Social Studies</span></p>
                <p><strong>Board:</strong> <span id="board"></span></p>
                <p><strong>Time Slot:</strong> <span id="time-slot"></span></p>
                <p><strong>Date:</strong> <span id="date"></span></p>
                <p className="mt-4"><strong>Price for 1 Month:</strong> ₹799</p>
                <div className="mt-6 flex gap-4">
                    <a href="/Pages/Students Registartion/my-teacher.html" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Dashboard</a>
                    <button onClick={proceedToPayment} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">Proceed to Payment</button>
                </div>
            </div>

            {/* Payment Confirmation Modal */}
            <div id="payment-modal" className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 hidden">
                <div className="bg-white p-6 rounded-lg shadow-md w-80">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-semibold">Did you complete the payment?</h2>
                        <button id="modal-close" className="text-gray-500 hover:text-gray-700 focus:outline-none">&times;</button>
                    </div>
                    <div className="flex justify-between">
                        <button id="modal-yes" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">Yes</button>
                        <button id="modal-no" className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600">No</button>
                    </div>
                </div>
            </div>

            {/* Thank You Modal */}
            <div id="modal-thank-you" className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 hidden">
                <div className="bg-white p-6 rounded-lg shadow-md w-80">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg font-semibold">Thank you for your confirmation.</h2>
                        <button id="modal-close-thank-you" className="text-gray-500 hover:text-gray-700 focus:outline-none">&times;</button>
                    </div>
                    <p>Your payment is being processed and the teacher will contact you soon.</p>
                    <div className="mt-4">
                        <button onClick={hideThankYouModal} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentEnrollPage;
