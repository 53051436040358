import React, { useEffect, useState } from 'react';
import { getDatabase, ref, set } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js";
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js";
import { useNavigate } from 'react-router-dom';
import { TextField, MenuItem } from "@mui/material";

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
    authDomain: "easy-tuition-dac4a.firebaseapp.com",
    projectId: "easy-tuition-dac4a",
    storageBucket: "easy-tuition-dac4a.appspot.com",
    messagingSenderId: "873415270262",
    appId: "1:873415270262:web:bda429aa2747af38346457",
    measurementId: "G-T286R1DSHZ"
};

const ClassList = () => {
    const [classes, setClasses] = useState([]);
    const [classDetails, setClassDetails] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const app = initializeApp(firebaseConfig);
        const database = getDatabase(app);

        const urlParams = new URLSearchParams(window.location.search);
        const phone = urlParams.get('phone');
        if (!phone) {
            alert('Phone number not found. Please ensure you have completed the previous steps.');
            navigate(`/TeacherRegistration`);
        } else {
            setPhoneNumber(phone);
        }
    }, [navigate]);

    const selectBoards = (classIndex, selectedBoards) => {
        setClassDetails(prevDetails => {
            const newDetails = [...prevDetails];
            if (!newDetails[classIndex]) {
                newDetails[classIndex] = { boards: [], class: classes[classIndex] };
            }
            newDetails[classIndex].boards = selectedBoards.map(board => ({
                board,
                subjects: []
            }));
            return newDetails;
        });
    };

    const selectSubjects = (classIndex, boardIndex, selectedSubjects) => {
        setClassDetails(prevDetails => {
            const newDetails = [...prevDetails];
            newDetails[classIndex].boards[boardIndex].subjects = selectedSubjects;
            return newDetails;
        });
    };

    const postChoosedClasses = async () => {
        if (classes.length === 0) {
            alert('Please select at least one class');
            return;
        }

        try {
            const database = getDatabase();
            await set(ref(database, `teachers/${phoneNumber}/classDetails/classes`), classDetails);
            
            navigate(`/TimeSlotSelection?phone=${encodeURIComponent(phoneNumber)}`);
        } catch (error) {
            console.error('Error saving class details:', error);
            alert('Failed to save class details. Please try again.');
        }
    };

    return (
        <div className="w-full h-auto flex items-center justify-center px-6 py-10">
            <div className="w-full h-full flex flex-col md:items-center gap-8">
                <div className="w-full h-1/2 flex items-center justify-center">
                    <div className="w-full md:w-1/2 h-full flex flex-col gap-4 items-center justify-center">
                        <div className="flex flex-col justify-center items-center">
                            <h1 className="text-2xl font-medium tracking-wide">
                                Choose your requirements
                            </h1>
                            <h1 className="text-xs text-gray-400">
                                Select the class you are interested to teach
                            </h1>
                        </div>

                        <TextField
                            onChange={(e) => setClasses(e.target.value)}
                            value={classes}
                            label="Select the Class"
                            fullWidth
                            InputProps={{ style: { borderRadius: "10px" } }}
                            select
                            SelectProps={{
                                multiple: true,
                            }}
                        >
                            <MenuItem value="Class 5">Class 5</MenuItem>
                            <MenuItem value="Class 6">Class 6</MenuItem>
                            <MenuItem value="Class 7">Class 7</MenuItem>
                            <MenuItem value="Class 8">Class 8</MenuItem>
                            <MenuItem value="Class 9">Class 9</MenuItem>
                            <MenuItem value="Class 10">Class 10</MenuItem>
                            <MenuItem value="Class +1">Class +1</MenuItem>
                            <MenuItem value="Class +2">Class +2</MenuItem>
                        </TextField>
                    </div>
                </div>

                {classes.map((items, index) => (
                    <div key={index} className="flex flex-col gap-5 py-5 w-full md:w-1/2">
                        <div className="flex flex-col gap-4 w-full h-auto py-8 px-3 bg-slate-100 rounded-2xl drop-shadow-md">
                            <h1 className="text-xl font-medium">{items}</h1>
                            <div className="w-full px-3 py-4 bg-white rounded-2xl drop-shadow-md flex flex-col gap-3">
                                <TextField
                                    onChange={(e) => selectBoards(index, e.target.value)}
                                    value={classDetails[index]?.boards?.map(board => board.board) || []}
                                    label="Select the boards"
                                    fullWidth
                                    InputProps={{ style: { borderRadius: "10px" } }}
                                    select
                                    SelectProps={{
                                        multiple: true,
                                    }}
                                >
                                    <MenuItem value="KERALA">Kerala State Syllabus (KSS)</MenuItem>
                                    <MenuItem value="CBSE"> CBSE (Central Board of Secondary Education)</MenuItem>
                                    <MenuItem value="ICSE"> ICSE (Indian Certificate of Secondary Education)</MenuItem>
                                    <MenuItem value="KVHSE"> Kerala Vocational Higher Secondary Education (KVHSE)</MenuItem>
                                </TextField>
                            </div>

                            {classDetails[index]?.boards?.map((board, boardIndex) => (
                                <div key={boardIndex} className="w-full px-3 py-4 bg-white rounded-2xl drop-shadow-md flex flex-col gap-3">
                                    <h1 className="text-xl font-medium">{board.board}</h1>
                                    <TextField
                                        onChange={(e) => selectSubjects(index, boardIndex, e.target.value)}
                                        value={board.subjects || []}
                                        label="Select the subjects"
                                        fullWidth
                                        InputProps={{ style: { borderRadius: "10px" } }}
                                        select
                                        SelectProps={{
                                            multiple: true,
                                        }}
                                    >
                                        <MenuItem value="English">English</MenuItem>
<MenuItem value="Malayalam">Malayalam</MenuItem>
<MenuItem value="Hindi">Hindi</MenuItem>
<MenuItem value="Mathematics">Mathematics</MenuItem>
<MenuItem value="Environmental Science">Environmental Science (EVS)</MenuItem>
<MenuItem value="Science">Science</MenuItem>
<MenuItem value="Social Science">Social Science</MenuItem>
<MenuItem value="Information Technology">Information Technology (IT)</MenuItem>
<MenuItem value="Physics">Physics</MenuItem>
<MenuItem value="Chemistry">Chemistry</MenuItem>
<MenuItem value="Biology">Biology</MenuItem>
<MenuItem value="Arabic">Arabic</MenuItem>
 <MenuItem value="Accountancy">Accountancy</MenuItem>
<MenuItem value="Business Studies">Business Studies</MenuItem>
<MenuItem value="Economics">Economics</MenuItem>
<MenuItem value="Computer Science">Computer Science</MenuItem>
<MenuItem value="Electronics">Electronics</MenuItem>
 <MenuItem value="History">History</MenuItem>
<MenuItem value="Geography">Geography</MenuItem>
<MenuItem value="Political Science">Political Science</MenuItem>
<MenuItem value="Sociology">Sociology</MenuItem>
 

                                    </TextField>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}

                <button
                    onClick={postChoosedClasses}
                    className="w-full md:w-1/2 h-12 bg-violet-500 rounded-2xl flex items-center justify-center text-white text-lg cursor-pointer"
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default ClassList;
