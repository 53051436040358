import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get } from 'https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js';
import { initializeApp } from 'https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
  authDomain: "easy-tuition-dac4a.firebaseapp.com",
  projectId: "easy-tuition-dac4a",
  storageBucket: "easy-tuition-dac4a.appspot.com",
  messagingSenderId: "873415270262",
  appId: "1:873415270262:web:bda429aa2747af38346457",
  measurementId: "G-T286R1DSHZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

const TeacherDashboard = () => {
  const [classes, setClasses] = useState([]);
  const [userId, setUserId] = useState(new URLSearchParams(window.location.search).get('userId'));

  useEffect(() => {
    fetchAndDisplayClasses();
  }, [userId]);

  const fetchStudentDetails = async (studentUserID) => {
    const studentRef = ref(db, `students/${studentUserID}`);
    try {
      const snapshot = await get(studentRef);
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log(`No details found for student ${studentUserID}`);
        return null;
      }
    } catch (error) {
      console.error(`Error fetching details for student ${studentUserID}:`, error);
      return null;
    }
  };

  const fetchAndDisplayClasses = async () => {
    const classesRef = ref(db, 'Classes');
    if (!userId) {
      setClasses([{ message: 'Invalid or missing user ID.' }]);
      return;
    }

    try {
      const snapshot = await get(classesRef);
      if (snapshot.exists()) {
        const classesData = snapshot.val();
        const userClasses = [];

        for (const classKey of Object.keys(classesData)) {
          const classData = classesData[classKey];
          if (classData.teacherUserID === userId) {
            const studentDetails = await fetchStudentDetails(classData.studentUserID);
            userClasses.push({ classData, studentDetails });
          }
        }

        setClasses(userClasses.length > 0 ? userClasses : [{ message: 'No classes found for this user.' }]);
      } else {
        setClasses([{ message: 'No classes found.' }]);
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
      setClasses([{ message: 'Error fetching classes. Please try again later.' }]);
    }
  };

  const generateMeetingLink = async (date, time) => {
    const apiUrl = 'https://easy-tution-backend-gmeet.vercel.app/create-meet';
    const duration = 60; // Default duration in minutes

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ date, time, duration }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate meeting link');
      }

      const responseBody = await response.json();
      return responseBody.meetingLink || 'Error: Unable to generate meeting link';
    } catch (error) {
      console.error('Error generating meeting link:', error);
      return 'Error: Unable to generate meeting link';
    }
  };

  const ClassCard = ({ classData, studentDetails }) => {
    const [meetingLink, setMeetingLink] = useState('');
    const [loading, setLoading] = useState(false);
    const currentDate = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format
    const [classDate, setClassDate] = useState(currentDate);
    const [classTime, setClassTime] = useState(classData.timeSlot.split(' ')[0]);

    const handleGenerateLink = async () => {
      setLoading(true);
      const link = await generateMeetingLink(classDate, classTime);
      setMeetingLink(link);
      setLoading(false);
    };

    return (
      <div className="bg-white shadow-lg rounded-lg p-6 mb-4 border border-gray-200">
        <div className="flex items-center mb-4">
          <img src={studentDetails?.personalDetails?.passportPhotoURL || ''} alt="Student Photo" className="w-16 h-16 rounded-full border border-gray-300 mr-4" />
          <div>
            <h2 className="text-2xl font-semibold text-gray-800 mb-1">{classData.subject}</h2>
            <p className="text-gray-600">{studentDetails?.personalDetails?.fullName || 'N/A'}</p>
            <p className="text-gray-500 text-sm">{studentDetails?.phone || 'Phone Number: N/A'}</p>
          </div>
        </div>
        <div className="border-t border-gray-200 pt-4 mt-4">
          <p className="text-gray-700"><strong>Board:</strong> {classData.board}</p>
          <p className="text-gray-700"><strong>Time Slot:</strong> {classData.timeSlot}</p>
          <p className="text-gray-700"><strong>Start Date:</strong> {classData.startDate}</p>
          <p className="text-gray-700"><strong>End Date:</strong> {classData.expiryDate}</p>
        </div>
        <div className="border-t border-gray-200 pt-4 mt-4">
          <div className="mb-4">
            <label className="block text-gray-700">Date:</label>
            <input type="date" value={classDate} onChange={(e) => setClassDate(e.target.value)} className="border border-gray-300 p-2 rounded-lg w-full" />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Time:</label>
            <input type="time" value={classTime} onChange={(e) => setClassTime(e.target.value)} className="border border-gray-300 p-2 rounded-lg w-full" />
          </div>
          <button onClick={handleGenerateLink} className="bg-blue-500 text-white px-4 py-2 rounded-lg" disabled={loading}>
            {loading ? 'Generating...' : 'Generate Meeting Link'}
          </button>
          <p className="mt-4 text-gray-600">{meetingLink && <a href={meetingLink} target="_blank" rel="noopener noreferrer" className="text-blue-500">{meetingLink}</a>}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-100 flex h-screen">
      {/* Mobile Menu Button */}
      <button id="mobileMenu" className="p-4 bg-violet-500 text-white fixed top-0 left-0 z-20">
        Menu
      </button>

      {/* Sidebar */}
      <div id="sidebar" className="w-64 bg-black text-white fixed h-full z-10 md:relative md:flex md:flex-col md:w-64 py-8 md:translate-x-0">
        <h1 className="text-2xl font-bold mb-8 text-center">Easy Tuition</h1>
        <ul className="space-y-4 text-left px-4">
          <li><a id="myStudentsLink" href="#" className="hover:underline">My Students</a></li>
          <li><a id="profileLink" href="#" className="hover:underline">Profile</a></li>
          <li><a id="helpLink" href="#" className="hover:underline">Help</a></li>
          <li><a id="paymentsLink" href="#" className="hover:underline">Payments</a></li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="container mx-auto p-8">
        {classes.map((item, index) => 
          item.message ? (
            <p key={index}>{item.message}</p>
          ) : (
            <ClassCard key={item.classData.classId} classData={item.classData} studentDetails={item.studentDetails} />
          )
        )}
      </div>
    </div>
  );
};

export default TeacherDashboard;
