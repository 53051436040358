import React, { useState, useEffect } from "react";
import {
  getDatabase,
  ref,
  set,
} from "https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js";
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
  authDomain: "easy-tuition-dac4a.firebaseapp.com",
  projectId: "easy-tuition-dac4a",
  storageBucket: "easy-tuition-dac4a.appspot.com",
  messagingSenderId: "873415270262",
  appId: "1:873415270262:web:bda429aa2747af38346457",
  measurementId: "G-T286R1DSHZ",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const BankDetails = () => {
  const [accNo, setAccNo] = useState("");
  const [holderName, setHolderName] = useState("");
  const [bank, setBank] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [upiid, setUpiid] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const phone = urlParams.get("phone");
    if (!phone) {
      alert(
        "Phone number not found. Please ensure you have completed the previous steps."
      );
      navigate(`/TeacherRegistration`);
    } else {
      setPhoneNumber(phone);
    }
  }, [navigate]);

  const postBankDetails = async () => {
    // Validate inputs
    if (!accNo || !holderName || !bank || !ifsc) {
      alert("Please fill in all the required fields");
      return;
    }

    // Validate IFSC code format (basic validation)
    const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
    if (!ifscRegex.test(ifsc)) {
      alert("Please enter a valid IFSC code");
      return;
    }

    try {
      const database = getDatabase();
      await set(ref(database, `teachers/${phoneNumber}/bank-details`), {
        accountNumber: accNo,
        accountHolderName: holderName,
        bankName: bank,
        ifscCode: ifsc,
        Upi: upiid

      });

      alert("Bank details saved successfully!");
      navigate(`/Introduction?phone=${encodeURIComponent(phoneNumber)}`);
    } catch (error) {
      console.error("Error saving bank details:", error);
      alert("Failed to save bank details. Please try again.");
    }
  };

  return (
    <div className="w-full min-h-screen px-6 py-10 flex justify-center">
      <div className="w-full md:w-1/2 h-full flex flex-col gap-10">
        <div className="flex flex-col gap-5">
          {/* <div className="w-32 h-32 flex items-center justify-center rounded-full bg-black"></div> */}
          <div>
            <h1 className="text-3xl font-medium">Bank Details</h1>
            <h1 className="text-gray-400">Please provide the bank details</h1>
          </div>
        </div>
        <div className="flex w-full flex-col gap-2">
          <TextField
            value={accNo}
            onChange={(e) => setAccNo(e.target.value)}
            label="Account Number"
            type="text"
            required
            InputProps={{ style: { borderRadius: "10px" } }}
          />
       

<TextField
            value={holderName}
            onChange={(e) => setHolderName(e.target.value)}
            label="Account Holder Name"
            type="text"
            required
            InputProps={{ style: { borderRadius: "10px" } }}
          />

          <TextField
            value={bank}
            onChange={(e) => setBank(e.target.value)}
            // label="Bank Name"
            type="text"
            required
            InputProps={{ style: { borderRadius: "10px" } }}
            select
            SelectProps={{
              native: true,
            }}
          >
            <option value="">Select a bank</option>
            <option value="State Bank of India">State Bank of India</option>
<option value="HDFC Bank">HDFC Bank</option>
<option value="ICICI Bank">ICICI Bank</option>
<option value="Axis Bank">Axis Bank</option>
<option value="Federal Bank">Federal Bank</option>
<option value="Punjab National Bank">Punjab National Bank</option>
<option value="Bank of Baroda">Bank of Baroda</option>
<option value="Canara Bank">Canara Bank</option>
<option value="Union Bank of India">Union Bank of India</option>
<option value="Bank of India">Bank of India</option>
<option value="IDBI Bank">IDBI Bank</option>
<option value="Kotak Mahindra Bank">Kotak Mahindra Bank</option>
<option value="Indian Bank">Indian Bank</option>
<option value="Yes Bank">Yes Bank</option>
<option value="IndusInd Bank">IndusInd Bank</option>
<option value="Central Bank of India">Central Bank of India</option>
<option value="Bank of Maharashtra">Bank of Maharashtra</option>
<option value="Oriental Bank of Commerce">Oriental Bank of Commerce</option>
<option value="UCO Bank">UCO Bank</option>
<option value="Indian Overseas Bank">Indian Overseas Bank</option>
<option value="South Indian Bank">South Indian Bank</option>
<option value="Dena Bank">Dena Bank</option>
<option value="Saraswat Bank">Saraswat Bank</option>
<option value="Nainital Bank">Nainital Bank</option>
<option value="Lakshmi Vilas Bank">Lakshmi Vilas Bank</option>
<option value="Tamilnad Mercantile Bank">Tamilnad Mercantile Bank</option>
<option value="Karur Vysya Bank">Karur Vysya Bank</option>

            {/* Add more banks as needed */}
          </TextField>
          <TextField
            value={ifsc}
            onChange={(e) => setIfsc(e.target.value.toUpperCase())}
            label="IFSC Code"
            type="text"
            required
            InputProps={{ style: { borderRadius: "10px" } }}
          />


<TextField
            value={upiid}
            onChange={(e) => setUpiid(e.target.value.toUpperCase())}
            label="UPI ID"
            type="text"
            required
            InputProps={{ style: { borderRadius: "10px" } }}
          />


        </div>
        <button
          onClick={postBankDetails}
          className="w-full rounded-xl h-12 bg-violet-500 flex items-center justify-center cursor-pointer"
        >
          <h1 className="text-white text-lg">Next</h1>
        </button>
      </div>
    </div>
  );
};

export default BankDetails;
