import React, { useEffect, useState } from 'react';
import { getDatabase, ref, get, query, remove } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js";
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js";
import StudentSideNav from '../../Components/Student/StudentSideNav'; // Update import to StudentSideNav

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
    authDomain: "easy-tuition-dac4a.firebaseapp.com",
    projectId: "easy-tuition-dac4a",
    storageBucket: "easy-tuition-dac4a.appspot.com",
    messagingSenderId: "873415270262",
    appId: "1:873415270262:web:bda429aa2747af38346457",
    measurementId: "G-T286R1DSHZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const MyTeacher = () => {
    const [teachers, setTeachers] = useState([]);
    const userId = new URLSearchParams(window.location.search).get('userId');

    useEffect(() => {
        const fetchTeachers = async () => {
            if (userId) {
                try {
                    const classesRef = ref(database, 'Classes');
                    const snapshot = await get(query(classesRef));
                    if (snapshot.exists()) {
                        const classes = snapshot.val();
                        const teacherList = [];

                        for (const [key, classData] of Object.entries(classes)) {
                            if (classData.studentUserID === userId) {
                                await displayTeacherDetails(classData, key, teacherList);
                                handleClassExpiry(classData, key);
                            }
                        }
                        setTeachers(teacherList);
                    } else {
                        console.log("No data available");
                    }
                } catch (error) {
                    console.error("Error fetching data: ", error);
                }
            }
        };

        fetchTeachers();
    }, [userId]);

    const displayTeacherDetails = async (classData, classKey, teacherList) => {
        try {
            const teacherDetailsRef = ref(database, `teachers/${classData.teacherUserID}/personalDetails`);
            const snapshot = await get(teacherDetailsRef);
            if (snapshot.exists()) {
                const teacherDetails = snapshot.val();
                const teacherItem = {
                    passportPhotoURL: teacherDetails.passportPhotoURL,
                    fullName: teacherDetails.fullName,
                    startDate: formatDate(classData.startDate),
                    expiryDate: formatDate(classData.expiryDate),
                    subject: classData.subject,
                    timeSlotType: classData.timeSlotType,
                    timeSlot: classData.timeSlot,
                    phoneNumber: classData.phoneNumber
                };
                teacherList.push(teacherItem);
            } else {
                console.log("Teacher details not found");
            }
        } catch (error) {
            console.error("Error fetching teacher details: ", error);
        }
    };

    const handleClassExpiry = (classData, classKey) => {
        const currentDate = new Date();
        const expiryDate = new Date(classData.expiryDate);
        const timeDiff = expiryDate.getTime() - currentDate.getTime();
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (daysDiff <= 3 && daysDiff > 0) {
            displayExpiryMessage(classData, daysDiff);
        }

        if (daysDiff <= 0) {
            deleteClass(classKey);
        }
    };

    const displayExpiryMessage = (classData, daysDiff) => {
        const expiryMessage = document.createElement('p');
        expiryMessage.classList.add('alert', 'alert-warning');
        expiryMessage.textContent = `The class for ${classData.subject} will end in ${daysDiff} days.`;
        document.body.appendChild(expiryMessage); // Adjust this as per your design
    };

    const deleteClass = async (classKey) => {
        try {
            const classRef = ref(database, `Classes/${classKey}`);
            await remove(classRef);
            console.log(`Class ${classKey} has been deleted due to expiry.`);
        } catch (error) {
            console.error("Error deleting class: ", error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <div className="flex">
            <StudentSideNav /> {/* Include the StudentSideNav component */}
            <div className="ml-64 p-6 bg-gray-100 flex-1">
                <h1 className="text-xl font-bold mb-4">My Teacher</h1>
                <div id="teacherList" className="space-y-2">
                    {teachers.map((teacher, index) => (
                        <div key={index} className="p-6 bg-white shadow-lg rounded-lg mb-4 flex items-center space-x-4">
                            <div className="flex-shrink-0">
                                <img src={teacher.passportPhotoURL} alt="Passport Photo" className="w-24 h-24 object-cover rounded-full border-2 border-gray-200" />
                            </div>
                            <div className="flex-1">
                                <h3 className="text-xl font-semibold mb-2">Name: {teacher.fullName}</h3>
                                <p hidden><strong>Teacher ID:</strong> {teacher.teacherUserID}</p>
                                <p><strong>Start Date:</strong> {teacher.startDate}</p>
                                <p><strong>End Date:</strong> {teacher.expiryDate}</p>
                                <p><strong>Subject:</strong> {teacher.subject}</p>
                                <p><strong>Time Slot Type:</strong> {teacher.timeSlotType}</p>
                                <p><strong>Time Slot:</strong> {teacher.timeSlot}</p>
                                <a href={`https://wa.me/${teacher.phoneNumber}`} target="_blank" rel="noopener noreferrer" className="inline-flex items-center mt-4 px-4 py-2 bg-green-500 text-white font-semibold rounded-full hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
                                    Chat with Teacher
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MyTeacher;
