import React, { useState } from 'react';
import { getDatabase, ref, set, get } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js";
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js";
import { getAuth, createUserWithEmailAndPassword } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-auth.js";
import { useNavigate } from 'react-router-dom';


const firebaseConfig = {
    apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
    authDomain: "easy-tuition-dac4a.firebaseapp.com",
    projectId: "easy-tuition-dac4a",
    storageBucket: "easy-tuition-dac4a.appspot.com",
    messagingSenderId: "873415270262",
    appId: "1:873415270262:web:bda429aa2747af38346457",
    measurementId: "G-T286R1DSHZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app); // Initialize Firebase Authentication

const StudentRegistration = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('+91');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [message, setMessage] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate


    const formatPhoneNumber = (phoneNumber) => {
        if (!phoneNumber.startsWith('+91')) {
            return '+91' + phoneNumber;
        }
        return phoneNumber;
    };

    const sendOTP = async (phoneNumber) => {
        const apiUrl = 'https://easy-tution-backend-otp.vercel.app/send-otp';

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phoneNumber: phoneNumber })
            });

            if (response.ok) {
                localStorage.setItem('phoneNumber', phoneNumber);
                setOtpSent(true);
                setMessage("OTP sent to " + phoneNumber);
            } else {
                const data = await response.text();
                setMessage("Error sending OTP: " + data);
            }
        } catch (error) {
            console.error("Error sending OTP:", error);
            setMessage("Error sending OTP: " + error.message);
        }
    };

    const verifyOTP = async () => {
        const phoneNumber = localStorage.getItem('phoneNumber');
        const apiUrl = 'https://easy-tution-backend-otp.vercel.app/verify-otp';

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ phoneNumber: phoneNumber, otp: otp })
            });

            if (response.ok) {
                setMessage("OTP verified successfully. You can now submit the form.");
            } else {
                const data = await response.text();
                setMessage("Invalid OTP: " + data);
            }
        } catch (error) {
            console.error("Error verifying OTP:", error);
            setMessage("Error verifying OTP: " + error.message);
        }
    };

    const handleSendOtp = () => {
        let formattedPhoneNumber = formatPhoneNumber(phone);
        if (termsAccepted) {
            sendOTP(formattedPhoneNumber);
        } else {
            setMessage('Please accept the terms and conditions.');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (otp) {
            await verifyOTP();

            createUserWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    const user = userCredential.user;
                    const userRef = ref(database, 'students/' + user.uid);
                    await set(userRef, {
                        name: name,
                        email: email,
                        phone: phone
                    });

                    setMessage("Registration successful!");
                    // Redirect to student personal details page with user ID as query parameter
                    // window.location.href = `student-personal-details.html?userId=${user.uid}`;

                    navigate(`/StudentPersonalDetails?userId=${user.uid}`);

                })
                .catch((error) => {
                    let errorMessage;
                    switch (error.code) {
                        case 'auth/email-already-in-use':
                            errorMessage = "This email is already in use. Please use a different email.";
                            break;
                        case 'auth/invalid-email':
                            errorMessage = "The email address is not valid. Please enter a valid email.";
                            break;
                        case 'auth/weak-password':
                            errorMessage = "The password is too weak. Please enter a stronger password.";
                            break;
                        default:
                            errorMessage = "Error signing up: " + error.message;
                            break;
                    }
                    console.error("Error signing up:", error);
                    setMessage(errorMessage);
                });
        } else {
            setMessage('Please enter OTP.');
        }
    };

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
                <h2 className="text-2xl font-bold mb-6">Sign Up</h2>
                <form id="signup-form" onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="name" className="block text-gray-700">Name</label>
                        <input
                            type="text"
                            id="name"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-gray-700">Email</label>
                        <input
                            type="email"
                            id="email"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="phone" className="block text-gray-700">Phone Number</label>
                        <input
                            type="text"
                            id="phone"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-gray-700">Password</label>
                        <input
                            type="password"
                            id="password"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button
                        type="button"
                        id="send-otp"
                        className="bg-blue-500 text-white px-4 py-2 rounded-md"
                        onClick={handleSendOtp}
                    >
                        Send OTP
                    </button>
                    {otpSent && (
                        <div className="mt-4">
                            <label htmlFor="otp" className="block text-gray-700">Enter OTP</label>
                            <input
                                type="text"
                                id="otp"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                        </div>
                    )}
                    <div className="mb-4 flex items-center">
                        <input
                            type="checkbox"
                            id="terms"
                            className="mr-2"
                            checked={termsAccepted}
                            onChange={() => setTermsAccepted(!termsAccepted)}
                        />
                        <label htmlFor="terms" className="text-gray-700">I accept all terms & conditions</label>
                    </div>
                    <button
                        type="submit"
                        id="submit-form"
                        className="bg-green-500 text-white px-4 py-2 rounded-md mt-4"
                    >
                        Submit
                    </button>
                </form>
                <p id="message" className="mt-4 text-red-500">{message}</p>
            </div>
        </div>
    );
};

export default StudentRegistration;
