import React from 'react';

const Tutorial = () => {
  return (
    <div className="flex flex-col items-center justify-center p-4">
      <h1 className="text-2xl font-bold mb-4">Tutorial</h1>
      <iframe
        className="w-full max-w-2xl aspect-video"
        src="https://www.youtube.com/embed/v1R_w0z18qg?si=DyZVvrIAJ3HPMapI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default Tutorial;
