import React from 'react';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TeacherRegistration from './Pages/Teachers/TeacherRegistration';
import PersonalDetails from './Pages/Teachers/PersonalDetails';
import CollegeDetails from './Pages/Teachers/CollegeDetails';
import ClassList from './Pages/Teachers/ClassList';
import ClassesTeach from './Pages/Teachers/ClassesTeach';
import TimeSlotSelection from './Pages/Teachers/TimeSlotSelection';
import BankDetails from './Pages/Teachers/BankDetails';
import Introduction from './Pages/Teachers/Introduction';
import RegistrationSuccessful from './Pages/Teachers/RegistrationSuccessful';
import TeacherLogin  from './Pages/Teachers/TeacherLogin';
import TeacherDashboard  from './Pages/Teachers/TeacherDashboard';
import TermsAndConditions  from './Pages/Teachers/TermsAndConditions';
import Tutorial  from './Pages/Teachers/Tutorial';

 
import StudentRegistration  from './Pages/Student/StudentRegistration';
import StudentPersonalDetails  from './Pages/Student/StudentPersonalDetails';
import StudentsParents  from './Pages/Student/StudentsParents';
import StudentRegistrationSuccessful  from './Pages/Student/StudentRegistrationSuccessful';
import StudentDashboard  from './Pages/Student/StudentDashboard';
import MyTeacher from './Pages/Student/MyTeacher';  
import FindTeacher from './Pages/Student/FindTeacher';  
import StudentEnrollPage from './Pages/Student/StudentEnrollPage';  
import PaymentPage from './Pages/Student/PaymentPage';  
 
 
 
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<TeacherRegistration />} />
          <Route path="/Personal-details" element={<PersonalDetails />} />
          <Route path="/CollegeDetails" element={<CollegeDetails />} />
          <Route path="/ClassList" element={<ClassList />} />
          <Route path="/ClassesTeach" element={<ClassesTeach/>} />
          <Route path="/TimeSlotSelection" element={<TimeSlotSelection />} />
          <Route path="/BankDetails" element={<BankDetails />} />
          <Route path="/Introduction" element={<Introduction />} />
          <Route path="/RegistrationSuccessful" element={<RegistrationSuccessful />} />
          <Route path="/TeacherLogin" element={<TeacherLogin />} />
          <Route path="/TeacherDashboard" element={<TeacherDashboard />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/Tutorial" element={<Tutorial />} />

 
          <Route path="/StudentRegistration" element={<StudentRegistration />} />
          <Route path="/StudentPersonalDetails" element={<StudentPersonalDetails />} />
          <Route path="/StudentsParents" element={<StudentsParents />} />
          <Route path="/StudentRegistrationSuccessful" element={<StudentRegistrationSuccessful />} />
          <Route path="/StudentDashboard" element={<StudentDashboard />} />
          <Route path="/MyTeacher" element={<MyTeacher />} />
          <Route path="/FindTeacher" element={<FindTeacher />} />
          <Route path="/StudentEnrollPage" element={<StudentEnrollPage />} />
          <Route path="/PaymentPage" element={<PaymentPage />} />

           
            
           
         </Routes>
      </Router>
    </div>
  );
}

export default App;
