import React from 'react';

const PaymentPage = () => {
  const handlePayment = () => {
    window.open(
      'https://payu.in/invoice/F0779739003D519FAEBB6EB274FC27937E7188F585220534625FAFB9C5BA7A91/00BD0775107D78FAD1A8FEBEA8417353',
      '_blank'
    );
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-6 bg-white shadow-md rounded-lg text-center">
        <h1 className="text-2xl font-semibold mb-6">Pay Your Invoice</h1>
        <p className="mb-6">Click the button below to proceed to the payment page.</p>
        <button
          onClick={handlePayment}
          className="px-6 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700 transition"
        >
          Go to Payment
        </button>
      </div>
    </div>
  );
};

export default PaymentPage;
