import React, { useState } from "react";
import "../../index.css";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import   Checkbox  from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import registrationWomen from '../../assets/Teacher/registarion-women.jpg';
 

import { initializeApp } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js";
import {
  getAuth,
  createUserWithEmailAndPassword,
} from "https://www.gstatic.com/firebasejs/10.13.0/firebase-auth.js";
import {
  getDatabase,
  ref,
  set,
} from "https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
  authDomain: "easy-tuition-dac4a.firebaseapp.com",
  projectId: "easy-tuition-dac4a",
  storageBucket: "easy-tuition-dac4a.appspot.com",
  messagingSenderId: "873415270262",
  appId: "1:873415270262:web:bda429aa2747af38346457",
  measurementId: "G-T286R1DSHZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

const TeacherRegistration = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });
  const [errors, setErrors] = useState({ password: "", general: "" });
 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

 
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
  };

  const handleLabelClick = () => {
      setIsChecked(!isChecked); // Toggle the checkbox state
      if (!isChecked) {
          navigate('/TermsAndConditions'); // Navigate to the target path if not checked
      }
  };

  const handleClick = () => {
    navigate('/Tutorial'); // Navigate to the tutorial page
  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, phone, password } = formData;

    // Clear previous errors
    setErrors({ password: "", general: "" });

    // Password validation
    if (password.length < 8) {
      setErrors((prev) => ({
        ...prev,
        password: "Password must be at least 8 characters long.",
      }));
      return;
    }

    // Phone number validation (optional, but recommended)
    const phoneRegex = /^[+]?[0-9]{10,15}$/; // Adjust regex according to your phone number format
    if (!phoneRegex.test(phone)) {
      setErrors((prev) => ({
        ...prev,
        general: "Please enter a valid phone number.",
      }));
      return;
    }

    try {
      // Create user
      await createUserWithEmailAndPassword(auth, email, password);

      // Save user data to the database
      await set(ref(database, "teachers/" + phone), {
        name,
        email,
        phone,
      });

      // Redirect to the next page with phone number as query parameter
      navigate(`/Personal-details?phone=${encodeURIComponent(phone)}`);
    } catch (error) {
      console.error("Error during registration:", error);
      if (error.code === "auth/email-already-in-use") {
        setErrors((prev) => ({
          ...prev,
          general:
            "This email address is already in use. Please use a different email.",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          general: "An error occurred during registration. Please try again.",
        }));
      }
    }
  };

  return (
    // <div className="container mx-auto p-8">
    //     <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Teacher Registration</h1>

    //     <form id="registrationForm" className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
    //         <div className="mb-4">
    //             <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">Name</label>
    //             <input
    //                 type="text"
    //                 id="name"
    //                 name="name"
    //                 value={formData.name}
    //                 onChange={handleChange}
    //                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    //                 required
    //             />
    //         </div>

    //         <div className="mb-4">
    //             <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email</label>
    //             <input
    //                 type="email"
    //                 id="email"
    //                 name="email"
    //                 value={formData.email}
    //                 onChange={handleChange}
    //                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    //                 required
    //             />
    //         </div>

    //         <div className="mb-4">
    //             <label htmlFor="phone" className="block text-gray-700 text-sm font-bold mb-2">Phone Number</label>
    //             <input
    //                 type="text"
    //                 id="phone"
    //                 name="phone"
    //                 value={formData.phone}
    //                 onChange={handleChange}
    //                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    //                 required
    //             />
    //         </div>

    //         <div className="mb-4">
    //             <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">Password</label>
    //             <input
    //                 type="password"
    //                 id="password"
    //                 name="password"
    //                 value={formData.password}
    //                 onChange={handleChange}
    //                 className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
    //                 required
    //             />
    //             {errors.password && <p className="text-red-500 text-xs italic mt-1">{errors.password}</p>}
    //         </div>

    //         {errors.general && <p className="text-red-500 text-xs italic mt-1">{errors.general}</p>}

    //         <div className="flex items-center justify-between">
    //             <button
    //                 type="submit"
    //                 className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    //             >
    //                 Register
    //             </button>
    //         </div>
    //     </form>
    // </div>
    <div className=" w-full pb-5">
      <form
        className=" w-full h-screen flex flex-col gap-4 items-center justify-center px-6"
        onSubmit={handleSubmit}
      >
        <div className="w-32 h-32 bg-black rounded-2xl">     
            <img src={registrationWomen} alt="Registration for women" />
        </div>
        <div className=" flex flex-col items-center">
          <h1 className=" text-2xl font-medium tracking-wide">
            Teacher Registration
          </h1>
          <h1 className=" text-gray-400 text-xs">
            let's get signed up, just a few simple details
          </h1>
        </div>
        <div className=" flex flex-col gap-2 w-full md:w-1/2 ">
          <TextField
            name="name"
            id="name"
            fullWidth
            label="Full Name"
            type="text"
            value={formData.name}
            InputProps={{ style: { borderRadius: "10px" } }}
            onChange={handleChange}
          />
          <TextField
            name="email"
            id="email"
            fullWidth
            label="email"
            type="email"
            value={formData.email}
            InputProps={{ style: { borderRadius: "10px" } }}
            onChange={handleChange}
          />
          <TextField
            name="phone"
            id="phone"
            fullWidth
            label="phone"
            type="phone"
            value={formData.phone}
            InputProps={{ style: { borderRadius: "10px" } }}
            onChange={handleChange}
          />
          <TextField
            value={formData.password}
            fullWidth
            label="password"
            type="password"
            name="password"
            id="password"
            InputProps={{ style: { borderRadius: "10px" } }}
            onChange={handleChange}
          />
          {errors.password && (
            <p className="text-red-500 text-xs italic mt-1">
              {errors.password}
            </p>
          )}
         

         <FormControlLabel
            control={
                <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    onClick={(e) => e.stopPropagation()} // Prevent label click from triggering checkbox change
                />
            }
            label={
                <span
                    onClick={handleLabelClick} // Handle label click
                    className="cursor-pointer underline" // Add underline and pointer cursor
                >
                    I accept the terms & conditions
                </span>
            }
        />




        </div>
        <button
          type="submit"
          //   onClick={handleSubmit}
          className=" w-full rounded-xl h-12 bg-blue-500 flex items-center justify-center md:w-1/2"
        >
          <h1 className=" text-white text-lg">Register</h1>
        </button>
      </form>
      <h1 className="  text-sm flex justify-center items-end mt-[-40px]">
        Don't know how to register?{" "}
 
        <span onClick={handleClick} className="font-semibold underline cursor-pointer">
      Watch Tutorial
    </span>


      </h1>
    </div>
  );
};

export default TeacherRegistration;
