import React, { useState, useEffect } from "react";
import {
  getDatabase,
  ref,
  set,
} from "https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js";
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js";
import { useNavigate } from "react-router-dom";
import { TextField, MenuItem } from "@mui/material";


const firebaseConfig = {
  apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
  authDomain: "easy-tuition-dac4a.firebaseapp.com",
  projectId: "easy-tuition-dac4a",
  storageBucket: "easy-tuition-dac4a.appspot.com",
  messagingSenderId: "873415270262",
  appId: "1:873415270262:web:bda429aa2747af38346457",
  measurementId: "G-T286R1DSHZ",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const TimeSlotSelection = () => {
  const [am, setAm] = useState([]);
  const [pm, setPm] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const phone = urlParams.get("phone");
    if (!phone) {
      alert(
        "Phone number not found. Please ensure you have completed the previous steps."
      );
      navigate(`/TeacherRegistration`);
    } else {
      setPhoneNumber(phone);
    }
  }, [navigate]);

  const postTimeSlots = async () => {
    if (am.length === 0 && pm.length === 0) {
      alert("Please select at least one time slot");
      return;
    }

    try {
      const database = getDatabase();
      await set(ref(database, `teachers/${phoneNumber}/timeSlots`), {
        AM: am,
        PM: pm
      });

      alert("Time slots saved successfully!");
      navigate(`/BankDetails?phone=${encodeURIComponent(phoneNumber)}`);
    } catch (error) {
      console.error("Error saving time slots:", error);
      alert("Failed to save time slots. Please try again.");
    }
  };

  return (
    <div className="w-full min0-h-screen px-6 py-10 flex items-center justify-center">
      <div className="w-full md:w-1/2 h-full flex flex-col gap-10">
        <div className="flex flex-col gap-3">
          {/* <div className="w-32 h-32 bg-black rounded-full"></div> */}
          <h1 className="text-3xl font-medium">Choose your time slots</h1>
          <h1 className="text-gray-400">
            You can select any time slots for teaching the class, which will be
            conducted every day.
          </h1>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex gap-5 items-center">
            <h1 className="text-xl font-medium">AM</h1>
            <TextField
              value={am}
              fullWidth
              select
              SelectProps={{ multiple: true }}
              label="Choose time in AM"
              onChange={(e) => setAm(e.target.value)}
            >
              <MenuItem value="07:00 AM - 08:00 AM">07:00 AM - 08:00 AM</MenuItem>
              <MenuItem value="08:00 AM - 09:00 AM">08:00 AM - 09:00 AM</MenuItem>
              <MenuItem value="09:00 AM - 10:00 AM">09:00 AM - 10:00 AM</MenuItem>
              <MenuItem value="10:00 AM - 11:00 AM">10:00 AM - 11:00 AM</MenuItem>
              <MenuItem value="11:00 AM - 12:00 PM">11:00 AM - 12:00 PM</MenuItem>
            </TextField>
          </div>
          <div className="flex gap-5 items-center">
            <h1 className="text-xl font-medium">PM</h1>
            <TextField
              value={pm}
              fullWidth
              select
              SelectProps={{ multiple: true }}
              label="Choose time in PM"
              onChange={(e) => setPm(e.target.value)}
            >
              <MenuItem value="12:00 PM - 01:00 PM">12:00 PM - 01:00 PM</MenuItem>
              <MenuItem value="01:00 PM - 02:00 PM">01:00 PM - 02:00 PM</MenuItem>
              <MenuItem value="02:00 PM - 03:00 PM">02:00 PM - 03:00 PM</MenuItem>
              <MenuItem value="03:00 PM - 04:00 PM">03:00 PM - 04:00 PM</MenuItem>
              <MenuItem value="04:00 PM - 05:00 PM">04:00 PM - 05:00 PM</MenuItem>
              <MenuItem value="05:00 PM - 06:00 PM">05:00 PM - 06:00 PM</MenuItem>
              <MenuItem value="06:00 PM - 07:00 PM">06:00 PM - 07:00 PM</MenuItem>
              <MenuItem value="07:00 PM - 08:00 PM">07:00 PM - 08:00 PM</MenuItem>
            </TextField>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex gap-2 items-center justify-center">
            <hr className="w-1/4 border border-1 border-gray-500" />
            <h1 className="text-lg">Selected Time</h1>
            <hr className="w-1/4 border-gray-500 border border-1" />
          </div>
          <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-5">
              {am.length !== 0 && <h1 className="text-xl font-medium">AM</h1>}
              <div className="flex flex-wrap gap-2 justify-start">
                {am.map((time, index) => (
                  <div
                    key={index}
                    className="w-auto h-auto px-2 py-2 flex items-center rounded-lg justify-between border shadow-md"
                  >
                    <h1>{time}</h1>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col gap-5">
              {pm.length !== 0 && <h1 className="text-xl font-medium">PM</h1>}
              <div className="flex flex-wrap gap-2 justify-start">
                {pm.map((time, index) => (
                  <div
                    key={index}
                    className="w-auto h-auto px-2 py-2 flex items-center rounded-lg justify-between border shadow-md"
                  >
                    <h1>{time}</h1>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <button
            onClick={postTimeSlots}
            className="w-full py-2 flex items-center rounded-lg justify-center h-auto bg-blue-500 cursor-pointer"
          >
            <h1 className="text-white text-lg">Next</h1>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TimeSlotSelection;
