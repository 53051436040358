import React, { useEffect, useState } from 'react';
import { getDatabase, ref, set, get } from 'firebase/database';
import { initializeApp } from 'firebase/app';
import { useNavigate } from 'react-router-dom';


// Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8',
  authDomain: 'easy-tuition-dac4a.firebaseapp.com',
  projectId: 'easy-tuition-dac4a',
  storageBucket: 'easy-tuition-dac4a.appspot.com',
  messagingSenderId: '873415270262',
  appId: '1:873415270262:web:bda429aa2747af38346457',
  measurementId: 'G-T286R1DSHZ',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

// Helper function to sanitize strings for use in selectors
function sanitizeForSelector(str) {
  if (typeof str !== 'string') {
    str = String(str); // Convert to string if it's not already
  }
  return str.replace(/[^a-z0-9-]/gi, '_');
}

const ClassesTeach = () => {
  const [classes, setClasses] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedBoards, setSelectedBoards] = useState({});


  const navigate = useNavigate(); // Initialize useNavigate


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const phone = urlParams.get('phone');
    if (!phone) {
      alert('Phone number not found. Please ensure you have completed the previous steps.');
      navigate(`/TeacherRegistration`);
      return;
    }
    setPhoneNumber(phone);

    get(ref(database, 'teachers/' + phone + '/classes'))
      .then((snapshot) => {
        const classesData = snapshot.val() || [];
        const classesToProcess = Array.isArray(classesData) ? classesData : Object.keys(classesData);
        setClasses(classesToProcess);
      })
      .catch((error) => {
        console.error('Error fetching classes: ', error);
      });
  }, []);

  const subjects = {
    'SCERT Kerala': ['Science', 'Social Studies', 'Mathematics', 'Biology', 'Chemistry', 'Physics', 'Geography', 'Economics', 'English', 'Hindi'],
    CBSE: ['Science', 'Social Studies', 'Mathematics', 'Biology', 'Chemistry', 'Physics', 'Geography', 'Economics', 'English', 'Hindi'],
    ICSE: ['Science', 'Social Studies', 'Mathematics', 'Biology', 'Chemistry', 'Physics', 'Geography', 'Economics', 'English', 'Hindi'],
    NIOS: ['Science', 'Social Studies', 'Mathematics', 'Biology', 'Chemistry', 'Physics', 'Geography', 'Economics', 'English', 'Hindi'],
  };

  const handleBoardChange = (className, board, isChecked) => {
    setSelectedBoards((prev) => {
      const updatedBoards = { ...prev };

      if (isChecked) {
        if (!updatedBoards[className]) {
          updatedBoards[className] = {};
        }
        updatedBoards[className][board] = subjects[board];
      } else {
        delete updatedBoards[className][board];
      }

      return updatedBoards;
    });
  };

  const handleSubmit = () => {
    const classData = classes.map((className) => {
      const boardsData = {};
      const boards = Object.keys(selectedBoards[className] || {});

      boards.forEach((board) => {
        const selectedSubjects = Array.from(document.querySelectorAll(`input[name="${sanitizeForSelector(className)}-${sanitizeForSelector(board)}-subject"]:checked`)).map((sb) => sb.value);
        if (selectedSubjects.length > 0) {
          boardsData[board] = selectedSubjects;
        }
      });

      return {
        class: className,
        boards: boardsData,
      };
    });

    set(ref(database, 'teachers/' + phoneNumber + '/classes'), classData)
      .then(() => {
        alert('Data saved successfully!');
          navigate(`/TimeSlotSelection?phone=${encodeURIComponent(phoneNumber)}`); 

         
      })
      .catch((error) => {
        console.error('Error saving data: ', error);
      });
  };

  return (
    <div className="bg-gray-100 flex items-center justify-center min-h-screen p-6">
      <div className="w-full max-w-2xl p-6 bg-white shadow-md rounded-lg" id="class-container">
        <h1 className="text-2xl font-bold mb-4 text-gray-800 text-center">Select Classes, Boards, and Subjects</h1>
        <p className="text-gray-600 mb-4 text-center">Please select the boards you teach for each class and the subjects you can handle.</p>
        {classes.map((className, index) => (
          <div className="mb-6 p-4 border border-gray-300 rounded-lg" key={index} id={`class-${sanitizeForSelector(className)}`}>
            <h2 className="text-xl font-semibold mb-2 text-gray-800">Class {className}</h2>
            <div className="mb-4">
              <h3 className="text-lg font-semibold text-gray-700">Select Boards</h3>
              {['SCERT Kerala', 'CBSE', 'ICSE', 'NIOS'].map((board) => (
                <label className="block text-gray-700 mb-1" key={board}>
                  <input
                    type="checkbox"
                    name={`${sanitizeForSelector(className)}-board`}
                    value={board}
                    className="mr-2 board-checkbox"
                    onChange={(e) => handleBoardChange(className, board, e.target.checked)}
                  />
                  {board}
                </label>
              ))}
            </div>
            {selectedBoards[className] &&
              Object.keys(selectedBoards[className]).map((board) => (
                <div key={board} className="mt-4">
                  <h3 className="text-lg font-semibold text-gray-700">Subjects for {board}</h3>
                  {selectedBoards[className][board].map((subject) => (
                    <label key={subject} className="block text-gray-600">
                      <input
                        type="checkbox"
                        name={`${sanitizeForSelector(className)}-${sanitizeForSelector(board)}-subject`}
                        value={subject}
                        className="mr-2"
                      />
                      {subject}
                    </label>
                  ))}
                </div>
              ))}
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-6">
        <button className="bg-blue-500 text-white px-6 py-3 rounded hover:bg-blue-600 focus:outline-none shadow-lg transition duration-300" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default ClassesTeach;
