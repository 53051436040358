import React, { useState } from 'react';
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-auth.js";
import { getDatabase, ref, get } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js";
import { useNavigate } from 'react-router-dom';

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
    authDomain: "easy-tuition-dac4a.firebaseapp.com",
    projectId: "easy-tuition-dac4a",
    storageBucket: "easy-tuition-dac4a.appspot.com",
    messagingSenderId: "873415270262",
    appId: "1:873415270262:web:bda429aa2747af38346457",
    measurementId: "G-T286R1DSHZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

const TeacherLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [resetEmail, setResetEmail] = useState('');
    const [showResetPopup, setShowResetPopup] = useState(false);
    const navigate = useNavigate(); // Initialize useNavigate


    const getTeacherDetailsByEmail = async (email) => {
        try {
            const teachersRef = ref(database, 'teachers');
            const snapshot = await get(teachersRef);
            if (snapshot.exists()) {
                const teachers = snapshot.val();
                for (let phone in teachers) {
                    if (teachers[phone].email === email) {
                        return {
                            phone: phone,
                            accountVerified: teachers[phone].accountVerified === true
                        };
                    }
                }
            }
            console.error('No data available for this email.');
            return { phone: null, accountVerified: false };
        } catch (error) {
            console.error("Error checking account verification status:", error);
            return { phone: null, accountVerified: false };
        }
    };

    const loginUser = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            const { phone, accountVerified } = await getTeacherDetailsByEmail(email);
            if (accountVerified && phone) {
                 navigate(`/TeacherDashboard?phone=${encodeURIComponent(phone)}`);

            } else {
                setErrorMessage('Your account is being reviewed. Please come back later.');
                setTimeout(() => {
                    setErrorMessage('');
                }, 4000);
            }
        } catch (error) {
            displayError(error.code);
        }
    };

    const resetPassword = async (e) => {
        e.preventDefault();
        if (!resetEmail) {
            setErrorMessage('Please enter your email address.');
            return;
        }
        try {
            await sendPasswordResetEmail(auth, resetEmail);
            alert('Password reset link sent to your email address.');
            setShowResetPopup(false);
        } catch (error) {
            console.error("Error sending reset email:", error); // Log the error for debugging
            displayError(error.code);
        }
    };

    const displayError = (errorCode) => {
        console.error('Error Code:', errorCode); // Log the error code for debugging
        switch (errorCode) {
            case 'auth/invalid-email':
                setErrorMessage('The email address is not valid. Please check and try again.');
                break;
            case 'auth/user-disabled':
                setErrorMessage('Your account has been disabled. Please contact support.');
                break;
            case 'auth/user-not-found':
                setErrorMessage('No user found with this email address. Please check and try again.');
                break;
            case 'auth/wrong-password':
                setErrorMessage('The password you entered is incorrect. Please try again.');
                break;
            default:
                setErrorMessage('An error occurred. Please try again later.');
                break;
        }

        setTimeout(() => {
            setErrorMessage('');
        }, 4000); // Clear the error message after 4 seconds
    };

    return (
        <div className="bg-gray-100 flex items-center justify-center h-screen">
            <div className="w-full max-w-md bg-white p-8 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-6 text-center">Login as Teacher</h2>
                
                <form id="loginForm" onSubmit={loginUser}>
                    <div className="mb-4">
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    
                    <div className="mb-6">
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    
                    <button
                        type="submit"
                        className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        Login
                    </button>

                    {errorMessage && <p className="mt-4 text-red-500">{errorMessage}</p>}

                    <p className="mt-4 text-sm text-gray-600 text-center">
                        <button
                            type="button"
                            onClick={() => setShowResetPopup(true)}
                            className="text-blue-500 hover:text-blue-600"
                        >
                            Forgot Password?
                        </button>
                    </p>
                </form>
            </div>

            {/* Popup for password reset */}
            {showResetPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className="bg-white p-8 rounded-lg shadow-md max-w-sm w-full">
                        <h2 className="text-xl font-bold mb-4">Reset Password</h2>
                        <p className="mb-4 text-gray-600">Enter your email address to receive a password reset link.</p>
                        <form id="resetPasswordForm" onSubmit={resetPassword}>
                            <div className="mb-4">
                                <label htmlFor="resetEmail" className="block text-sm font-medium text-gray-700">Email</label>
                                <input
                                    type="email"
                                    id="resetEmail"
                                    name="resetEmail"
                                    required
                                    value={resetEmail}
                                    onChange={(e) => setResetEmail(e.target.value)}
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            >
                                Send Reset Link
                            </button>
                            <button
                                type="button"
                                onClick={() => setShowResetPopup(false)}
                                className="mt-4 text-blue-500 hover:text-blue-600"
                            >
                                Close
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TeacherLogin;
