import React, { useEffect, useState } from 'react';
import { getDatabase, ref, set } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js";
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js";
import { getAuth } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-auth.js";
import { useNavigate } from 'react-router-dom';

const firebaseConfig = {
    apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
    authDomain: "easy-tuition-dac4a.firebaseapp.com",
    projectId: "easy-tuition-dac4a",
    storageBucket: "easy-tuition-dac4a.appspot.com",
    messagingSenderId: "873415270262",
    appId: "1:873415270262:web:bda429aa2747af38346457",
    measurementId: "G-T286R1DSHZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const auth = getAuth(app);

const StudentsParents = () => {
    const [userId, setUserId] = useState('');
    const [guardianType, setGuardianType] = useState('');
    const [guardianName, setGuardianName] = useState('');
    const [guardianNumber, setGuardianNumber] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate

    // Function to get query parameter
    const getQueryParameter = (param) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (userId && guardianType && guardianName && guardianNumber) {
            try {
                // Save parent/guardian details in Firebase Realtime Database
                const parentRef = ref(database, `students/${userId}/parentDetails`);
                await set(parentRef, {
                    guardianType,
                    guardianName,
                    guardianNumber
                });

                setMessage("Parent/Guardian details saved successfully!");

                // Redirect to the success page with userId
                // window.location.href = `successful-register.html?userId=${userId.uid}`;
                navigate(`/StudentRegistrationSuccessful?userId=${userId}`);


            } catch (error) {
                console.error("Error saving parent details:", error);
                setMessage(`Error saving parent details: ${error.message}`);
            }
        } else {
            setMessage("Please fill in all fields.");
        }
    };

    // Set userId from query parameter when the component mounts
    useEffect(() => {
        const id = getQueryParameter('userId');
        if (id) {
            setUserId(id);
        } else {
            setMessage("User ID not found.");
        }
    }, []);

    return (
        <div className="bg-gray-100 p-6">
            <div className="container mx-auto bg-white p-6 rounded-lg shadow-md">
                <h1 className="text-2xl font-semibold mb-4">Parent/Guardian Details</h1>
                <form id="parent-details-form" onSubmit={handleSubmit}>
                    <input type="hidden" id="userId" value={userId} />
                    
                    <div className="mb-4">
                        <label htmlFor="guardian-type" className="block text-gray-700">Select Guardian</label>
                        <select
                            id="guardian-type"
                            className="w-full p-2 border border-gray-300 rounded"
                            value={guardianType}
                            onChange={(e) => setGuardianType(e.target.value)}
                            required
                        >
                            <option value="" disabled>Select Guardian</option>
                            <option value="father">Father</option>
                            <option value="mother">Mother</option>
                            <option value="grandfather">Grandfather</option>
                            <option value="grandmother">Grandmother</option>
                            <option value="friend">Friend</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="guardian-name" className="block text-gray-700">Guardian Name</label>
                        <input
                            type="text"
                            id="guardian-name"
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Enter Guardian's Name"
                            value={guardianName}
                            onChange={(e) => setGuardianName(e.target.value)}
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="guardian-number" className="block text-gray-700">Guardian Number</label>
                        <input
                            type="text"
                            id="guardian-number"
                            className="w-full p-2 border border-gray-300 rounded"
                            placeholder="Enter Guardian's Phone Number"
                            value={guardianNumber}
                            onChange={(e) => setGuardianNumber(e.target.value)}
                            required
                        />
                    </div>

                    <button type="submit" className="bg-blue-500 text-white p-2 rounded">Submit</button>
                    {message && <p id="message" className="mt-4 text-red-500">{message}</p>}
                </form>
            </div>
        </div>
    );
};

export default StudentsParents;
