// StudentDashboard.jsx
import React from 'react';
import { useLocation } from 'react-router-dom';
import StudentSideNav from '../../Components/Student/StudentSideNav'; // Update import to StudentSideNav

const StudentDashboard = () => {
    const location = useLocation();
    const userId = new URLSearchParams(location.search).get('userId');

    return (
        <div className="flex">
            <StudentSideNav userId={userId} /> {/* Use the updated component */}
            <main className="flex-grow p-6">
                <h1 className="text-2xl font-bold">Welcome to your Dashboard, User ID: {userId}</h1>
                {/* You can add additional content here based on the selected menu */}
            </main>
        </div>
    );
};

export default StudentDashboard;
