// StudentSideNav.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

const StudentSideNav = ({ userId }) => {
    const navigate = useNavigate();

    const handleMenuClick = (menu) => {
        if (menu === 'MyTeacher') {
            navigate(`/MyTeacher?userId=${userId}`);
        } else if (menu === 'FindTeacher') {
            navigate(`/FindTeacher?userId=${userId}`);
        }
    };

    return (
        <aside className="w-64 bg-gray-800 text-white min-h-screen">
            <div className="p-4">
                <h2 className="text-lg font-semibold">Dashboard</h2>
                <ul className="mt-4">
                    <li
                        className="hover:bg-gray-700 p-2 cursor-pointer"
                        onClick={() => handleMenuClick('MyTeacher')}
                    >
                        My Teacher
                    </li>
                    <li
                        className="hover:bg-gray-700 p-2 cursor-pointer"
                        onClick={() => handleMenuClick('FindTeacher')}
                    >
                        Find Teacher
                    </li>
                </ul>
            </div>
        </aside>
    );
};

export default StudentSideNav;
