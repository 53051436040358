import React from 'react';
import { getDatabase, ref, set } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-storage.js";
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js";
import { getAuth } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-auth.js";
import { useNavigate } from 'react-router-dom';


 


// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
    authDomain: "easy-tuition-dac4a.firebaseapp.com",
    projectId: "easy-tuition-dac4a",
    storageBucket: "easy-tuition-dac4a.appspot.com",
    messagingSenderId: "873415270262",
    appId: "1:873415270262:web:bda429aa2747af38346457",
    measurementId: "G-T286R1DSHZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const storage = getStorage(app);
const auth = getAuth(app);

// Main component
const StudentPersonalDetails = () => {
    // Get user ID from query string
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('userId');
    const navigate = useNavigate(); // Initialize useNavigate

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (userId) {
            const fullName = e.target.fullName.value;
            const dob = e.target.dob.value;
            const primaryLanguage = e.target.primaryLanguage.value;
            const address = e.target.address.value;
            const pincode = e.target.pincode.value;
            const state = e.target.state.value;
            const passportPhotoFile = e.target.passportPhoto.files[0];
            const studentIdProofFile = e.target.studentIdProof.files[0];

            try {
                // Upload files to Firebase Storage
                const passportPhotoRef = storageRef(storage, `students/${userId}/personalDetails/passportPhoto`);
                const studentIdProofRef = storageRef(storage, `students/${userId}/personalDetails/studentIdProof`);

                const passportPhotoSnapshot = await uploadBytes(passportPhotoRef, passportPhotoFile);
                const studentIdProofSnapshot = await uploadBytes(studentIdProofRef, studentIdProofFile);

                const passportPhotoURL = await getDownloadURL(passportPhotoSnapshot.ref);
                const studentIdProofURL = await getDownloadURL(studentIdProofSnapshot.ref);

                // Save data to Firebase Realtime Database
                const personalDetailsRef = ref(database, `students/${userId}/personalDetails`);
                await set(personalDetailsRef, {
                    fullName,
                    dob,
                    primaryLanguage,
                    address,
                    pincode,
                    state,
                    passportPhotoURL,
                    studentIdProofURL
                });

                document.getElementById('message').innerText = "Personal details saved successfully!";
                
                // Navigate to the next page with userId as a query parameter
                // window.location.href = `students-parents-details.html?userId=${userId}`;
                navigate(`/StudentsParents?userId=${userId}`);



            } catch (error) {
                console.error("Error saving personal details:", error);
                document.getElementById('message').innerText = "Error saving personal details: " + error.message;
            }
        } else {
            document.getElementById('message').innerText = "User ID not found.";
        }
    };

    return (
        <div className="bg-gray-100 p-4">
            <div className="max-w-md mx-auto bg-white p-6 rounded-lg shadow-lg">
                <h1 className="text-2xl font-bold mb-4">Student Personal Details</h1>
                <form id="personal-details-form" onSubmit={handleSubmit}>
                    <label className="block mb-2">
                        <span className="text-gray-700">Full Name</span>
                        <input name="fullName" type="text" className="form-input mt-1 block w-full" required />
                    </label>
                    <label className="block mb-2">
                        <span className="text-gray-700">Date of Birth</span>
                        <input name="dob" type="date" className="form-input mt-1 block w-full" required />
                    </label>
                    <label className="block mb-2">
                        <span className="text-gray-700">Primary Language</span>
                        <input name="primaryLanguage" type="text" className="form-input mt-1 block w-full" required />
                    </label>
                    <label className="block mb-2">
                        <span className="text-gray-700">Address</span>
                        <input name="address" type="text" className="form-input mt-1 block w-full" required />
                    </label>
                    <label className="block mb-2">
                        <span className="text-gray-700">Pincode</span>
                        <input name="pincode" type="text" className="form-input mt-1 block w-full" required />
                    </label>
                    <label className="block mb-2">
                        <span className="text-gray-700">State</span>
                        <input name="state" type="text" className="form-input mt-1 block w-full" required />
                    </label>
                    <label className="block mb-2">
                        <span className="text-gray-700">Passport Photo</span>
                        <input name="passportPhoto" type="file" className="form-input mt-1 block w-full" accept="image/*" required />
                    </label>
                    <label className="block mb-2">
                        <span className="text-gray-700">Student ID Proof</span>
                        <input name="studentIdProof" type="file" className="form-input mt-1 block w-full" accept="image/*" required />
                    </label>
                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 mt-4 rounded">Submit</button>
                    <p id="message" className="mt-4 text-red-500"></p>
                </form>
            </div>
        </div>
    );
};

export default StudentPersonalDetails;
