import React, { useState, useEffect } from "react";
import {
  getDatabase,
  ref,
  set,
} from "https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js";
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js";
import { MdOutlineFileUpload } from "react-icons/md";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "https://www.gstatic.com/firebasejs/10.13.0/firebase-storage.js";
import { useNavigate } from "react-router-dom";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
  authDomain: "easy-tuition-dac4a.firebaseapp.com",
  projectId: "easy-tuition-dac4a",
  storageBucket: "easy-tuition-dac4a.appspot.com",
  messagingSenderId: "873415270262",
  appId: "1:873415270262:web:bda429aa2747af38346457",
  measurementId: "G-T286R1DSHZ",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const storage = getStorage(app);

const Introduction = () => {
  const [intro, setIntro] = useState("");
  const [introVideo, setIntroVideo] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const phone = urlParams.get("phone");
    if (!phone) {
      alert("Phone number not found. Please ensure you have completed the previous steps.");
      navigate(`/TeacherRegistration`);
    } else {
      setPhoneNumber(phone);
    }
  }, [navigate]);

  const postTeacherIntro = async () => {
    if (!intro || !introVideo) {
      alert("Please provide both introduction text and demo video.");
      return;
    }

    try {
      // Upload video to Firebase Storage
      const videoRef = storageRef(
        storage,
        `teacherVideos/${phoneNumber}_${Date.now()}_${introVideo.name}`
      );

      // Show loading state
      const loadingAlert = alert("Uploading video... Please wait.");

      await uploadBytes(videoRef, introVideo);
      const videoURL = await getDownloadURL(videoRef);

      // Save introduction text and video URL to Firebase Database
      await set(ref(database, `teachers/${phoneNumber}/introduction`), {
        introductionText: intro,
        demoVideoURL: videoURL,
      });

      // Set account verification status
      await set(
        ref(database, `teachers/${phoneNumber}/accountVerified`),
        false
      );

      // Close loading alert and show success message
      alert("Introduction saved successfully!");
      
      // Navigate to success page
      navigate(`/RegistrationSuccessful?phone=${encodeURIComponent(phoneNumber)}`);
    } catch (error) {
      console.error("Error saving introduction:", error);
      alert(`Failed to save introduction: ${error.message}`);
    }
  };

  return (
    <div className="w-full min-h-screen flex justify-center py-10 px-6">
      <div className="w-full md:w-1/2 flex flex-col gap-10">
        <div className="flex flex-col gap-3">
          {/* <div className="h-32 w-32 rounded-full bg-black"></div> */}
          <div>
            <h1 className="text-2xl tracking-wide font-medium">Introduction</h1>
            <h1 className="text-gray-400">Explain about yourself</h1>
          </div>
        </div>
        <textarea
          value={intro}
          onChange={(e) => setIntro(e.target.value)}
          className="border border-1 border-gray-300 rounded-3xl px-3 py-3"
          cols="30"
          rows="10"
          placeholder="Introduction"
          required
        ></textarea>
        <div className="flex flex-col gap-5">
          <div>
            <h1 className="text-2xl">Demo Video</h1>
            <h1 className="text-gray-400 text-sm">
              select any topic and explain it in two minutes
            </h1>
          </div>
          <TextField
            onChange={(e) => setIntroVideo(e.target.files[0])}
            type="file"
            accept="video/*"
            required
            InputProps={{
              style: { borderRadius: "10px", width: "200px" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <MdOutlineFileUpload />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <button
          onClick={postTeacherIntro}
          className="w-full rounded-xl h-12 bg-violet-500 flex items-center justify-center cursor-pointer"
        >
          <h1 className="text-white text-lg">Register</h1>
        </button>
      </div>
    </div>
  );
};

export default Introduction;
