import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RegistrationSuccessful = () => {
    const [userId, setUserId] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate


    // Function to get query parameter
    const getQueryParameter = (param) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    };

    // Use effect to get userId when the component mounts
    useEffect(() => {
        const id = getQueryParameter('userId');
        if (!id) {
            setMessage("User ID not found.");
        } else {
            setUserId(id);
        }
    }, []);

    // Handle button click to redirect to student dashboard
    const handleContinue = () => {
        if (userId) {
            // window.location.href = `StudentDashboard.html?userId=${userId}`;
            navigate(`/StudentDashboard?userId=${userId}`);
 

        } else {
            setMessage("User ID not found.");
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="max-w-lg w-full bg-white p-8 rounded-lg shadow-lg">
                <h1 className="text-2xl font-bold mb-4 text-green-600">Congratulations!</h1>
                <p className="text-gray-700 mb-6">Successfully you have been registered.</p>
                <button
                    id="continue-button"
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    onClick={handleContinue}
                >
                    Continue
                </button>
                {message && <p id="message" className="mt-4 text-red-500">{message}</p>}
            </div>
        </div>
    );
};

export default RegistrationSuccessful;
