import React, { useEffect } from "react";
import "../../index.css";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { MdOutlineFileUpload } from "react-icons/md";
import { MenuItem } from "@mui/material";

// Import Firebase modules
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js";
import { getAuth } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-auth.js";
import {
  getDatabase,
  ref as dbRef,
  update,
} from "https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "https://www.gstatic.com/firebasejs/10.13.0/firebase-storage.js";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
  authDomain: "easy-tuition-dac4a.firebaseapp.com",
  projectId: "easy-tuition-dac4a",
  storageBucket: "easy-tuition-dac4a.appspot.com",
  messagingSenderId: "873415270262",
  appId: "1:873415270262:web:bda429aa2747af38346457",
  measurementId: "G-T286R1DSHZ",
};

const PersonalDetails = () => {
  const navigate = useNavigate(); // Use the useNavigate hook

  useEffect(() => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const database = getDatabase(app);
    const storage = getStorage(app);

    // Get the phone number from the query string
    const urlParams = new URLSearchParams(window.location.search);
    const phone = urlParams.get("phone");

    // Ensure phone number is present
    if (!phone) {
      console.error("Phone number not found.");
      alert(
        "Phone number not found. Please ensure you have completed the previous steps."
      );
      navigate(`/TeacherRegistration `);
      return; // Exit if no phone
    }

    // Form submission handler
    const handleSubmit = async (e) => {
      e.preventDefault();

      // Remove fullName since it's not in the form anymore
      const dob = e.target.dob.value;

      const primaryLanguage = e.target.primaryLanguage.value;
      const secondaryLanguage = e.target.secondaryLanguage.value;
      const address = e.target.address.value.trim();
      const pincode = e.target.pincode.value.trim();
      const state = e.target.state.value.trim();
      const passportPhoto = e.target.file.files[0]; // Changed from passportPhoto to file to match your form
  
      const fullName = e.target.fullName.value.trim(); // Get fullName from the input

      if (!passportPhoto) {
        alert("Please upload a passport photo.");
        return;
      }

      try {
        // Upload the passport photo to Firebase Storage
        const photoRef = storageRef(storage, `passportPhotos/${phone}.jpg`);
        await uploadBytes(photoRef, passportPhoto);

        // Get the download URL for the uploaded photo
        const photoURL = await getDownloadURL(photoRef);

        // Save the personal details and photo URL to the database
        await update(
          dbRef(database, "teachers/" + phone + "/personalDetails"),
          {
            // Remove fullName from here as well
            fullName, // Save fullName
            dob,
            primaryLanguage,
            secondaryLanguage,
            address,
            pincode,
            state,
            passportPhotoURL: photoURL,
          }
        );

        // Redirect to the college details page with phone number as a query parameter
        navigate(`/CollegeDetails?phone=${encodeURIComponent(phone)}`);
      } catch (error) {
        console.error("Error saving personal details or uploading photo:", error);
        alert(`An error occurred: ${error.message}. Please try again.`);
      }
    };

    // Attach event listener
    const form = document.getElementById("personalDetailsForm");
    form.addEventListener("submit", handleSubmit);

    // Cleanup
    return () => {
      form.removeEventListener("submit", handleSubmit);
    };
  }, [navigate]); // Add navigate as a dependency

  return (
  
    <div className=" w-full h-screen px-6 flex items-center justify-center">
      <div className=" w-full h-full flex flex-col items-start md:items-center justify-center gap-10">
        <div className=" flex flex-col gap-1 md:items-center ">
          {/* <div className=" w-24 h-24 bg-black rounded-2xl"></div> */}
          <div className=" md:text-center">
            <h1 className=" text-xl tracking-wide font-medium">
              Personal Information
            </h1>
            <h1 className=" text-xs text-gray-400">
              Please fill your personal details
            </h1>
          </div>
        </div>
        <form id="personalDetailsForm" className=" flex flex-col gap-3 w-full md:w-1/2">

         

        <TextField
            name="fullName" // Correctly name this field
            id="fullName"
            fullWidth
            required
            label="Full Name"
            type="text"
            InputProps={{ style: { borderRadius: "10px" } }}
          />



        <TextField
            name="address"
            id="address"
            fullWidth
            required
            label="Address"
            type="text"
            InputProps={{ style: { borderRadius: "10px" } }}
            // onChange={(e) => setAddress(e.target.value)}
          />

 

<div className="flex flex-col"> 
  <label htmlFor=""> Date of Birth</label>
          <TextField
            name="dob"
            id="dob"
            fullWidth
            required
            label=""
            type="date"
            InputProps={{ style: { borderRadius: "10px" } }}
            // onChange={(e) => setDob(e.target.value)}
          />
</div>          

            

        

         

  

        {/* Row for Primary Language and Secondary Language */}
        <div className="flex gap-3">
        <TextField
          name="primaryLanguage"
          id="primaryLanguage"
          label="Primary Language"
          select
          required
          fullWidth
          InputProps={{ style: { borderRadius: "10px" } }}
        >
          <MenuItem value="English">English</MenuItem>
          <MenuItem value="Malayalam">Malayalam</MenuItem>
          <MenuItem value="Hindi">Hindi</MenuItem>
          <MenuItem value="Tamil">Tamil</MenuItem>
          <MenuItem value="Arabic">Arabic</MenuItem>
        </TextField>
        
        <TextField
          name="secondaryLanguage"
          id="secondaryLanguage"
          label="Secondary Language"
          select
          required
          fullWidth
          InputProps={{ style: { borderRadius: "10px" } }}
        >
          <MenuItem value="English">English</MenuItem>
          <MenuItem value="Malayalam">Malayalam</MenuItem>
          <MenuItem value="Hindi">Hindi</MenuItem>
          <MenuItem value="Tamil">Tamil</MenuItem>
          <MenuItem value="Arabic">Arabic</MenuItem>
        </TextField>
      </div>


          
          <div className=" flex gap-3">


          <TextField
          name="state"
          id="state"
          label="State"
          required
          select
          fullWidth
          InputProps={{ style: { borderRadius: "10px" } }}
        >
          <MenuItem value="Kerala">Kerala</MenuItem>
           

        </TextField>


    



            <TextField
              name="pincode"
              id="pincode"
              required
              fullWidth
              label="Pincode"
              type="text"
              InputProps={{ style: { borderRadius: "10px" } }}
              //   onChange={(e) => setPincode(e.target.value)}
            />

 


          </div>



          <div className="flex flex-col"> 
<label >Passport Size Photo</label>
             <TextField
              name="file"
              id="file"
              required
              fullWidth
              type="file"
 
              //   onChange={(e) => setPicUrl(e.target.files[0])}
              InputProps={{
                style: { borderRadius: "10px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <MdOutlineFileUpload />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <button
            type="submit"
            // onClick={postPersonalInfo}
            className=" w-full rounded-xl h-12 bg-blue-500 flex items-center justify-center"
          >
            <h1 className=" text-white text-lg">Next</h1>
          </button>
        </form>
      </div>
    </div>
  );
};

export default PersonalDetails;
