import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="max-w-3xl mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Terms and Conditions for Easy Tuition Teachers Platform</h1>
            <p className="mb-4">Last Updated: [30-31-2024]</p>
            <p className="mb-4">
                Welcome to the Easy Tuition Teachers Platform. By registering as a teacher on our platform, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
            </p>
            <ol className="list-decimal ml-6 mb-4">
                <li className="mb-2">
                    <strong>Acceptance of Terms</strong><br />
                    By accessing or using the Easy Tuition platform, you confirm that you accept these terms and conditions and agree to abide by them. If you do not agree with any part of these terms, please do not use our services.
                </li>
                <li className="mb-2">
                    <strong>Eligibility</strong><br />
                    To be eligible to register as a teacher on Easy Tuition, you must:
                    <ul className="list-disc ml-6">
                        <li>Be at least 18 years of age.</li>
                        <li>Possess the necessary qualifications and credentials to teach your selected subjects.</li>
                        <li>Have a valid government-issued identification.</li>
                    </ul>
                </li>
                <li className="mb-2">
                    <strong>Registration</strong><br />
                    You must create an account to access our services.
                    <ul className="list-disc ml-6">
                        <li>You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</li>
                        <li>You agree to provide accurate, current, and complete information during the registration process.</li>
                    </ul>
                </li>
                <li className="mb-2">
                    <strong>Responsibilities of Teachers</strong><br />
                    Teachers are expected to deliver quality education and adhere to professional standards.
                    <ul className="list-disc ml-6">
                        <li>You are responsible for ensuring that your teaching materials do not infringe on any copyrights or intellectual property rights.</li>
                        <li>You agree to provide timely responses to student inquiries and maintain communication as per the agreed schedule.</li>
                    </ul>
                </li>
                <li className="mb-2">
                    <strong>Payment Terms</strong><br />
                    Teachers will be compensated for their services as outlined in their individual agreements with Easy Tuition.
                    <ul className="list-disc ml-6">
                        <li>Payment details will be provided through the platform, and teachers are responsible for providing accurate payment information.</li>
                    </ul>
                </li>
                <li className="mb-2">
                    <strong>Code of Conduct</strong><br />
                    Teachers must treat all students with respect and maintain a professional demeanor at all times.
                    <ul className="list-disc ml-6">
                        <li>Any form of harassment, discrimination, or inappropriate behavior toward students is strictly prohibited.</li>
                    </ul>
                </li>
                <li className="mb-2">
                    <strong>Termination</strong><br />
                    Easy Tuition reserves the right to suspend or terminate your account at any time if you violate these terms or engage in any conduct that we believe is harmful to our platform or community.
                    <ul className="list-disc ml-6">
                        <li>You may terminate your account at any time by following the account termination process outlined on the platform.</li>
                    </ul>
                </li>
                <li className="mb-2">
                    <strong>Limitation of Liability</strong><br />
                    Easy Tuition is not liable for any direct, indirect, incidental, or consequential damages resulting from your use of the platform or any services provided by teachers.
                    <ul className="list-disc ml-6">
                        <li>Teachers are responsible for their own actions and any consequences arising from them.</li>
                    </ul>
                </li>
                <li className="mb-2">
                    <strong>Changes to Terms</strong><br />
                    Easy Tuition reserves the right to modify these terms at any time. We will notify you of any changes by posting the updated terms on our website. Your continued use of the platform after changes are posted constitutes your acceptance of the revised terms.
                </li>
                <li className="mb-2">
                    <strong>Governing Law</strong><br />
                    These terms shall be governed by and construed in accordance with the laws of [Insert Jurisdiction]. Any disputes arising from these terms shall be subject to the exclusive jurisdiction of the courts of [Insert Jurisdiction].
                </li>
                <li className="mb-2">
                    <strong>Contact Information</strong><br />
                    For any questions or concerns regarding these terms, please contact us at <a href="mailto:easytuitionmes@gmail.com" className="text-blue-600 hover:underline">easytuitionmes@gmail.com</a>.
                </li>
            </ol>
        </div>
    );
};

export default TermsAndConditions;
