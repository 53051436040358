import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { MenuItem, InputAdornment, IconButton, FormControl,    InputLabel, Select} from "@mui/material";
import { MdOutlineFileUpload } from "react-icons/md";
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js";
import {
  getDatabase,
  ref,
  set,
} from "https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "https://www.gstatic.com/firebasejs/10.13.0/firebase-storage.js";
import { useNavigate } from "react-router-dom";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
  authDomain: "easy-tuition-dac4a.firebaseapp.com",
  projectId: "easy-tuition-dac4a",
  storageBucket: "easy-tuition-dac4a.appspot.com",
  messagingSenderId: "873415270262",
  appId: "1:873415270262:web:bda429aa2747af38346457",
  measurementId: "G-T286R1DSHZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const storage = getStorage(app);

const CollegeDetails = () => {
  const [collegeName, setCollegeName] = useState("");
  const [collegeAddress, setCollegeAddress] = useState("");
  const [course, setCourse] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [yearOfJoined, setYearOfJoined] = useState("");
  const [graduationYear, setGraduationYear] = useState("");
  const [collegeIDProof, setCollegeIDProof] = useState(null);

  


  const navigate = useNavigate();

  const handleSubmit = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const phone = urlParams.get("phone");

    // Validate fields
    if (
      !collegeName ||
      !collegeAddress ||
      !course ||
      !currentYear ||
      !yearOfJoined ||
      !graduationYear ||
      !collegeIDProof
    ) {
      alert("Please fill out all required fields.");
      return;
    }

    try {
      let collegeIDProofURL = "";

      // Upload college ID proof
      if (collegeIDProof) {
        const collegeIDProofRef = storageRef(
          storage,
          `collegeIDProofs/${phone}`
        );
        await uploadBytes(collegeIDProofRef, collegeIDProof);
        collegeIDProofURL = await getDownloadURL(collegeIDProofRef);
      }

      // Save college details to the database
      await set(ref(database, `teachers/${phone}/collegeDetails`), {
        collegeName,
        collegeAddress,
        course,
        currentYear,
        yearOfJoined,
        graduationYear,
        collegeIDProof: collegeIDProofURL || null,
      });

      alert("College details saved successfully!");
      navigate(`/ClassList?phone=${encodeURIComponent(phone)}`);
    } catch (error) {
      console.error("Error saving college details:", error);
      alert("Failed to save college details. Please try again.");
    }
  };


   // Options for college names and addresses
   const collegeNames = [
    { value: 'MES Kalladi College Mannarkkad', label: 'MES Kalladi College Mannarkkad' },
    // Add more colleges if needed
  ];

  const collegeAddresses = [
    { value: 'Palakkad - Kozhikode Hwy, Kunthipuzha, College PO, Mannarkkad, Kerala 678583', label: 'Palakkad - Kozhikode Hwy, Kunthipuzha, College PO, Mannarkkad, Kerala 678583' },
    // Add more addresses if needed
  ];
  return (
    <div className="w-full h-screen px-6 flex items-center justify-center ">
      <div className=" w-full h-full flex flex-col items-start md:items-center justify-center gap-10">
        <div className=" flex flex-col gap-1 md:items-center ">
          {/* <div className=" w-24 h-24 bg-black rounded-2xl"></div> */}
          <div className=" md:text-center">
            <h1 className=" text-xl tracking-wide font-medium">
              College Details
            </h1>
            <h1 className=" text-xs text-gray-400">
              Please fill your personal details
            </h1>
          </div>
        </div>
        <div className=" flex flex-col gap-3 w-full md:w-1/2">


          


<FormControl fullWidth variant="outlined" style={{ borderRadius: "10px", marginBottom: "16px" }}>
        <InputLabel>College Name</InputLabel>
        <Select
          value={collegeName}
          onChange={(e) => setCollegeName(e.target.value)}
          label="College Name"
          style={{ borderRadius: "10px" }}
        >
          {collegeNames.map((college) => (
            <MenuItem key={college.value} value={college.value}>
              {college.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth variant="outlined" style={{ borderRadius: "10px" }}>
        <InputLabel>College Address</InputLabel>
        <Select
          value={collegeAddress}
          onChange={(e) => setCollegeAddress(e.target.value)}
          label="College Address"
          style={{ borderRadius: "10px" }}
        >
          {collegeAddresses.map((address) => (
            <MenuItem key={address.value} value={address.value}>
              {address.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>




<div className=" flex gap-3">


 
          <TextField
            label="Course"
            fullWidth

            select
            value={course}
            InputProps={{ style: { borderRadius: "10px" } }}
            onChange={(e) => setCourse(e.target.value)}
          >
           <MenuItem value="B.Sc.">B.Sc.</MenuItem>
<MenuItem value="BCA">BCA</MenuItem>
<MenuItem value="B.A.">B.A.</MenuItem>
<MenuItem value="BBA">BBA</MenuItem>
<MenuItem value="M.Sc.">M.Sc.</MenuItem>
<MenuItem value="M.A.">M.A.</MenuItem>
<MenuItem value="M.Com">M.Com</MenuItem>
<MenuItem value="B.Com">B.Com</MenuItem>
<MenuItem value="B.Voc">B.Voc</MenuItem>
<MenuItem value="B.Sc. in Food Technology">B.Sc. in Food Technology</MenuItem>
<MenuItem value="Bachelor of Computer Application (BCA)">Bachelor of Computer Application (BCA)</MenuItem>
<MenuItem value="Bachelor of Business Administration (BBA)">Bachelor of Business Administration (BBA)</MenuItem>
<MenuItem value="Master of Commerce (M.Com.)">Master of Commerce (M.Com.)</MenuItem>
<MenuItem value="M.Sc. in Chemistry">M.Sc. in Chemistry</MenuItem>
<MenuItem value="B.Sc. in Computer Science">B.Sc. in Computer Science</MenuItem>
<MenuItem value="B.A. in English">B.A. in English</MenuItem>
<MenuItem value="M.A. in History">M.A. in History</MenuItem>
<MenuItem value="B.Sc. in Botany">B.Sc. in Botany</MenuItem>
<MenuItem value="B.Com. in Co-Operation">B.Com. in Co-Operation</MenuItem>
<MenuItem value="M.Sc. in Botany">M.Sc. in Botany</MenuItem>
<MenuItem value="B.A. in Arabic and Islamic History">B.A. in Arabic and Islamic History</MenuItem>
<MenuItem value="M.A. in Economics">M.A. in Economics</MenuItem>
<MenuItem value="M.Sc. in Physics">M.Sc. in Physics</MenuItem>
<MenuItem value="B.A. in Economics">B.A. in Economics</MenuItem>
<MenuItem value="B.Sc. in Chemistry">B.Sc. in Chemistry</MenuItem>
<MenuItem value="B.A. in Mass Communication">B.A. in Mass Communication</MenuItem>
<MenuItem value="B.A. in History">B.A. in History</MenuItem>
<MenuItem value="M.A. in Islamic History">M.A. in Islamic History</MenuItem>
<MenuItem value="B.Sc. in Mathematics">B.Sc. in Mathematics</MenuItem>

          </TextField>

 
         
          <TextField
            onChange={(e) => setYearOfJoined(e.target.value)}
            value={yearOfJoined}
            fullWidth
            label="Joined Year"
            type="text"
            InputProps={{ style: { borderRadius: "10px" } }}
          />

</div>


<div className=" flex gap-3">
 
          <TextField
            onChange={(e) => setCurrentYear(e.target.value)}
            value={currentYear}
            fullWidth
            label="Current Year"
            type="text"
            InputProps={{ style: { borderRadius: "10px" } }}
          />
 
 
          <TextField
            onChange={(e) => setGraduationYear(e.target.value)}
            value={graduationYear}
            fullWidth
            label="Graduate Year"
             type="text"
             required
            InputProps={{ style: { borderRadius: "10px" } }}
          />
 

</div>
 

 
<div className="flex flex-col w-full"> 
<label >College Id Proof</label>
          <TextField
            onChange={(e) => setCollegeIDProof(e.target.files[0])}
            fullWidth
            name="file"
            required
            type="file"
            InputProps={{
              style: { borderRadius: "10px" },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <MdOutlineFileUpload />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          </div>

          <button
            onClick={handleSubmit}
            className=" w-full rounded-xl h-12 bg-blue-500 flex items-center justify-center cursor-pointer"
          >
            <h1 className=" text-white text-lg">Next</h1>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CollegeDetails;
