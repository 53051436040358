import React, { useEffect, useState } from 'react';
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-app.js";
import { getDatabase, ref, get } from "https://www.gstatic.com/firebasejs/10.13.0/firebase-database.js";
import StudentSideNav from '../../Components/Student/StudentSideNav';
import { useNavigate, useLocation } from 'react-router-dom';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAmcSLnzLFs8wvFh5vH3B82D1KFLFkbpm8",
    authDomain: "easy-tuition-dac4a.firebaseapp.com",
    projectId: "easy-tuition-dac4a",
    storageBucket: "easy-tuition-dac4a.appspot.com",
    messagingSenderId: "873415270262",
    appId: "1:873415270262:web:bda429aa2747af38346457",
    measurementId: "G-T286R1DSHZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const FindTeacher = () => {
    const [allTeachers, setAllTeachers] = useState({});
    const [subject, setSubject] = useState('');
    const [board, setBoard] = useState('');
    const [classLevel, setClassLevel] = useState('');
    const [timeSlotType, setTimeSlotType] = useState('');
    const [timeSlot, setTimeSlot] = useState('');
    const [filteredTeachers, setFilteredTeachers] = useState({});
 
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        loadAllTeachers();
    }, []);

    const loadAllTeachers = async () => {
        const teachersRef = ref(database, 'teachers');
        try {
            const snapshot = await get(teachersRef);
            if (snapshot.exists()) {
                setAllTeachers(snapshot.val());
            } else {
                setAllTeachers({});
            }
        } catch (error) {
            console.error("Error loading teachers:", error);
            setAllTeachers({});
        }
    };

    const handleFilterSubmit = (e) => {
        e.preventDefault();
        if (subject && board && classLevel && timeSlotType && timeSlot) {
            const filtered = filterTeachers(allTeachers, subject, board, classLevel, timeSlotType, timeSlot);
            setFilteredTeachers(filtered);
        } else {
            setFilteredTeachers({});
        }
    };

    const filterTeachers = (teachers, subject, board, classLevel, timeSlotType, timeSlot) => {
        return Object.entries(teachers).reduce((filtered, [phone, teacher]) => {
            if (teacher.classes) {
                const hasMatchingClass = Object.values(teacher.classes).some(classInfo => {
                    return classInfo.class === classLevel && classInfo.boards[board]?.includes(subject);
                });

                const hasMatchingTimeSlot = teacher.timeSlots?.[timeSlotType]?.includes(timeSlot);
                if (hasMatchingClass && hasMatchingTimeSlot) {
                    filtered[phone] = teacher;
                }
            }
            return filtered;
        }, {});
    };

    const getTimeSlotOptions = () => {
        if (!timeSlotType) return [];
        return timeSlotType === 'AM' ? [
            "07:00 AM - 08:00 AM",
            "08:00 AM - 09:00 AM",
            "09:00 AM - 10:00 AM",
            "10:00 AM - 11:00 AM",
            "11:00 AM - 12:00 PM"
        ] : [
            "12:00 PM - 01:00 PM",
            "01:00 PM - 02:00 PM",
            "02:00 PM - 03:00 PM",
            "03:00 PM - 04:00 PM",
            "04:00 PM - 05:00 PM",
            "05:00 PM - 06:00 PM",
            "06:00 PM - 07:00 PM",
            "07:00 PM - 08:00 PM",
            "08:00 PM - 09:00 PM"
        ];
    };

    const getQueryParameter = (param) => {
        const searchParams = new URLSearchParams(location.search);
        return searchParams.get(param);
    };

    const enrollTeacher = (phone, teacher) => {
        const userId = getQueryParameter('userId');
        const teacherUserID = phone;
        const teacherName = teacher.personalDetails?.fullName || 'Unknown';

        const queryString = new URLSearchParams({
            userId: userId,
            teacherUserID: teacherUserID,
            subject: subject,
            board: board,
            classLevel: classLevel,
            timeSlotType: timeSlotType,
            timeSlot: timeSlot,
            teacherName: teacherName
        }).toString();

        navigate(`/StudentEnrollPage?${queryString}`);
    };

    const watchVideo = (url) => {
        if (url && url !== '#') {
            window.open(url, '_blank');
        } else {
            alert('Demo video is not available for this teacher.');
        }
    };

    return (
        <div className="flex flex-col lg:flex-row bg-gray-100 min-h-screen">
            <StudentSideNav />
            <div className="flex-grow p-6">
                <h1 className="text-3xl font-bold mb-6 text-center">Find a New Teacher</h1>

                <div className="bg-white p-6 rounded-lg shadow-md mb-6">
                    <form id="filter-form" className="space-y-4" onSubmit={handleFilterSubmit}>
                        <div>
                            <label htmlFor="subject" className="block text-sm font-medium text-gray-700">Subject</label>
                            <select id="subject" name="subject" value={subject} onChange={(e) => setSubject(e.target.value)} className="mt-1 block w-full bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                <option value="">Select Subject</option>
                                <option value="English">English</option>
                                <option value="Hindi">Hindi</option>
                                <option value="Mathematics">Mathematics</option>
                                <option value="Social Studies">Social Studies</option>
                                <option value="Science">Science</option>
                            </select>
                        </div>

                        <div>
                            <label htmlFor="board" className="block text-sm font-medium text-gray-700">Board</label>
                            <select id="board" name="board" value={board} onChange={(e) => setBoard(e.target.value)} className="mt-1 block w-full bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                <option value="">Select Board</option>
                                <option value="SCERT Kerala">SCERT Kerala</option>
                                <option value="CBSE">CBSE</option>
                                <option value="ICSE">ICSE</option>
                                <option value="NIOS">NIOS</option>
                            </select>
                        </div>

                        <div>
                            <label htmlFor="class" className="block text-sm font-medium text-gray-700">Class</label>
                            <select id="class" name="class" value={classLevel} onChange={(e) => setClassLevel(e.target.value)} className="mt-1 block w-full bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                <option value="">Select Class</option>
                                <option value="Class 5">Class 5</option>
                                <option value="Class 6">Class 6</option>
                                <option value="Class 7">Class 7</option>
                                <option value="Class 8">Class 8</option>
                                <option value="Class 9">Class 9</option>
                                <option value="Class 10">Class 10</option>
                                <option value="Class 11">Class 11</option>
                                <option value="Class 12">Class 12</option>
                            </select>
                        </div>

                        <div>
                            <label htmlFor="timeSlotType" className="block text-sm font-medium text-gray-700">Time Slot Type</label>
                            <select id="timeSlotType" name="timeSlotType" value={timeSlotType} onChange={(e) => { setTimeSlotType(e.target.value); setTimeSlot(''); }} className="mt-1 block w-full bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                <option value="">Select AM/PM</option>
                                <option value="AM">AM</option>
                                <option value="PM">PM</option>
                            </select>
                        </div>

                        <div>
                            <label htmlFor="timeSlot" className="block text-sm font-medium text-gray-700">Specific Time</label>
                            <select id="timeSlot" name="timeSlot" value={timeSlot} onChange={(e) => setTimeSlot(e.target.value)} className="mt-1 block w-full bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm" disabled={!timeSlotType}>
                                <option value="">Select Time Slot</option>
                                {getTimeSlotOptions().map((slot, index) => (
                                    <option key={index} value={slot}>{slot}</option>
                                ))}
                            </select>
                        </div>

                        <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-500">Find Teachers</button>
                    </form>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
                    {Object.entries(filteredTeachers).length > 0 ? (
                        Object.entries(filteredTeachers).map(([phone, teacher]) => (
                            <div
                                key={phone}
                                className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                            >
                                <div className="flex items-center mb-4">
                                    <img
                                        className="w-24 h-24 object-cover rounded-full shadow-md mr-4"
                                        src={teacher.personalDetails?.passportPhotoURL}
                                        alt={teacher.fullName}
                                    />
                                    <div>
                                        <h3 className="text-xl font-semibold text-gray-800">
                                            {teacher.personalDetails?.fullName}
                                        </h3>
                                        <p className="text-gray-500">{phone}</p>
                                    </div>
                                </div>

                                <p className="mt-2 text-gray-700">
                                    <strong>Introduction:</strong>{' '}
                                    {teacher.introduction?.introductionText || 'Not available'}
                                </p>

                                <p className="mt-2 text-gray-700">
                                    <strong>Primary Language:</strong>{' '}
                                    {teacher.personalDetails?.primaryLanguage || 'Not specified'}
                                </p>
                                <p className="text-gray-700">
                                    <strong>Secondary Language:</strong>{' '}
                                    {teacher.personalDetails?.secondaryLanguage || 'Not specified'}
                                </p>

                                <p className="text-gray-700">
                                    <strong>Time Slots:</strong>{' '}
                                    {teacher.timeSlots &&
                                        Object.entries(teacher.timeSlots)
                                            .map(([type, slots]) => `${type}: ${slots.join(', ')}`)
                                            .join(' | ')}
                                </p>

                                <div className="flex justify-between mt-6">
                                    <button
                                        onClick={() => enrollTeacher(phone, teacher)}
                                        className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-400 transition-colors duration-200"
                                    >
                                        Enroll
                                    </button>
                                    <button
                                        onClick={() => watchVideo(teacher.introduction?.demoVideoURL)}
                                        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-400 transition-colors duration-200"
                                    >
                                        Watch Demo
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-gray-500 col-span-full text-center">
                            No teachers found.
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FindTeacher;